import * as zod from 'zod';

import { SpeedProfile } from '@sb/routine-runner/speed-profile';

import { MillerWeldParameters } from '../../MillerWeldMachine/types/MillerWeldMachineCommands';
import { StitchingConfig } from '../types/StitchingConfig';

export const WeldArguments = zod.object({
  selectedMachineID: zod.string().optional(),
  selectedTorchID: zod.string().optional(),
  millerWeldParameters: MillerWeldParameters,
  craterFillTime: zod.number(), // how long to wait for crater fill
  arcStartTime: zod.number(), // how long to hold before moving});
  travelSpeed: zod.number(),
  approachSpeedProfile: SpeedProfile,
  weldTravelSpeedProfile: SpeedProfile,
  stitching: StitchingConfig,
});

export type WeldArguments = zod.infer<typeof WeldArguments>;
