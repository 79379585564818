import * as zod from 'zod';

import {
  ActionRequiredError,
  MotionSpeed,
  SpeedLimitOption,
  Step,
} from '@sb/remote-control/types';
import { calculateSpeedProfileFromStepMotionSpeed } from '@sb/remote-control/util/calculateSpeedProfileFromStepMotionSpeed';
import { countStepKinds } from '@sb/remote-control/util/countStepKinds';
import { listAllSteps } from '@sb/remote-control/util/listAllSteps';
import type { StepKind } from '@sb/routine-runner';
import { type SpeedProfile } from '@sb/routine-runner';

import {
  MillerWeldParameters,
  MILLER_WFS_BOUNDS,
} from '../../../MillerWeldMachine/types/MillerWeldMachineCommands';
import { TARGET_POINTS_STEP_KIND } from '../../shared';
import { DeviceKinds } from '../../types/DeviceKinds';
import { StitchingConfig } from '../../types/StitchingConfig';

export namespace WeldStepDatabase {
  export const name = 'Weld';
  export const description = 'Perform a weld';
  export const deviceKinds = DeviceKinds;
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'Weld';

  export const permittedChildStepKinds: readonly StepKind[] = [
    'Waypoint',
    'Arc',
  ];

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    selectedMachineID: zod.string().optional(), // ID of selected machine. Make this optional for backwards compatibility.
    selectedTorchID: zod.string().optional(), // ID of selected torch. Make this optional for backwards compatibility.
    millerWeldParameters: MillerWeldParameters.default({
      voltage: 17,
      mode: 'constantVoltage',
      arcLength: 0.25,
      arcControl: 25,
      wireFeedSpeed: MILLER_WFS_BOUNDS.min, // Meters per second
    }),
    arcStartTime: zod.number().gte(0).default(0), // seconds
    craterFillTime: zod.number().gte(0).default(0), // seconds
    travelSpeed: zod.number().gte(0).default(MILLER_WFS_BOUNDS.min), // meters per second
    approachSpeed: zod.preprocess(
      (val) => (val == null ? undefined : val),
      MotionSpeed.optional(),
    ),
    approachSpeedOption: SpeedLimitOption.exclude(['PARENT_DEFAULTS']).default(
      'NO_MOTION_LIMITS',
    ),
    stitching: StitchingConfig.default({
      enabled: false,
      stitch: 0.015875, // 5/8 inch
      space: 0.015875, // 5/8 inch
    }),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({ step, stepConfiguration }) => {
    if (stepConfiguration?.args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const { args } = stepConfiguration;

    if (args.selectedMachineID == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Must set a weld machine.',
        fieldId: 'selectedMachineID',
      });
    }

    if (args.selectedTorchID == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Must set a torch.',
        fieldId: 'selectedTorchID',
      });
    }

    if (
      !step.steps.every((nestedStep) =>
        permittedChildStepKinds.includes(nestedStep.stepKind),
      )
    ) {
      const badStep = step.steps.find(
        (nestedStep) => !permittedChildStepKinds.includes(nestedStep.stepKind),
      );

      if (badStep == null) {
        // Should never happen
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Weld steps must contain only valid child steps.',
        });
      }

      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: `Weld steps may not contain ${badStep.name} steps.`,
      });
    }

    return null;
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    baseSpeedProfile,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const approachSpeedProfile: SpeedProfile =
      calculateSpeedProfileFromStepMotionSpeed({
        motionSpeed: args.approachSpeed,
        baseSpeedProfile,
      });

    const weldTravelSpeedProfile: SpeedProfile =
      calculateSpeedProfileFromStepMotionSpeed({
        motionSpeed: {
          motionSpeedPercent: null,
          customLimits: {
            ...baseSpeedProfile,
            maxTooltipSpeed: args.travelSpeed,
          },
        },
        baseSpeedProfile,
      });

    return {
      ...stepData,
      stepKind: 'Weld',
      args: {
        ...args,
        approachSpeedProfile,
        weldTravelSpeedProfile,
      },
    };
  };

  /**
   * Step description for the weld step is "Weld through X points"
   *
   * NOTE (MW,2024-12-05) Getting the number of points in the weld is somewhat compute-intensive. Could be misleading with loops, etc. A simpler solution would be to just do `return '  '`. Notice that we are returning an empty string _with_ whitespace. This prevents the step description from being autofilled.
   */
  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration,
    routine,
    includeStepName,
  }) => {
    if (stepConfiguration?.stepKind !== argumentKind) return null;
    if (routine == null) return null;

    // Find the current step with all of its substeps
    const allSteps = listAllSteps(routine?.steps || []);
    const step = allSteps.find((s) => s.id === stepConfiguration.id);
    if (step === undefined) return null;

    const targetPoints = countStepKinds(step, TARGET_POINTS_STEP_KIND);

    const msg = [
      includeStepName ? 'Weld' : false,
      `through ${targetPoints}`,
      `point${targetPoints === 1 ? '' : 's'}`,
    ]
      .filter((s) => s != null && s !== false)
      .join(' ');

    return msg;
  };
}

WeldStepDatabase satisfies Step.StepKindInfo;
