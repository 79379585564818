import * as zod from 'zod';

export const USBMetadatum = zod.object({
  name: zod.string().optional(),
  mountpoint: zod.string().nullable().optional(),
  tran: zod.string().optional(),
  size: zod.string().optional(),
  model: zod.string().nullable().optional(),
  serial: zod.string().nullable().optional(),
  fsavail: zod.string().nullable().optional(),
});

export const USBMetadata = zod.object({
  ...USBMetadatum.shape,
  children: zod.array(USBMetadatum).optional(),
});

export type USBMetadata = zod.infer<typeof USBMetadata>;
