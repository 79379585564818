import cx from 'classnames';
import { forwardRef } from 'react';

import {
  useComponentColorClassName,
  type ComponentColorOrDefault,
  type ComponentSize,
} from '../../utility';

import { BUTTON_COLOR_CLASSES } from './buttonColor';
import { BUTTON_SIZE_CLASSES } from './buttonSize';
import type { ButtonVariant } from './buttonVariant';
import { BUTTON_VARIANT_CLASSES } from './buttonVariant';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ComponentSize;
  variant?: ButtonVariant;
  color?: ComponentColorOrDefault;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button', // default to `button` rather than `submit`
      size = 36,
      variant = 'Gray',
      color,
      className,
      ...rest
    },
    ref,
  ) => {
    const colorClassName = useComponentColorClassName(
      color,
      BUTTON_COLOR_CLASSES[variant],
    );

    return (
      <button
        type={type}
        className={cx(
          className,
          BUTTON_SIZE_CLASSES[size],
          BUTTON_VARIANT_CLASSES[variant],
          colorClassName,
          'enabled:active:tw-brightness-90',
          'enabled:hover:tw-brightness-110',
          'disabled:tw-text-label-quaternary',
          'disabled:tw-cursor-not-allowed',
          'tw-select-none',
          'tw-cursor-pointer', // Ensure cursor changes on hover
          'tw-pointer-events-auto', // Ensure button is clickable
        )}
        {...rest}
        ref={ref}
      />
    );
  },
);
