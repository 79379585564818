import * as zod from 'zod';

import { RobotControlModeKindEnum } from '@sb/sb-robot-sdk/src/codegen/typescript-axios';

export const StandardBotsRO1Command = zod.object({
  kind: zod.literal('StandardBotsRO1Command'),
  subCommand: zod.discriminatedUnion('kind', [
    zod.object({
      kind: zod.literal('setControlMode'),
      controlMode: zod.nativeEnum(RobotControlModeKindEnum),
    }),
  ]),
});

export type StandardBotsRO1Command = zod.infer<typeof StandardBotsRO1Command>;
