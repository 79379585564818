import cx from 'classnames';
import { forwardRef } from 'react';

import Typography from './Typography';

import styles from './Switch.module.css';

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftLabel?: string;
  rightLabel?: string;
}

const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const {
    id,
    disabled,
    leftLabel,
    rightLabel,
    checked,
    className,
    onChange,
    ...other
  } = props;

  return (
    <label
      className={cx(
        styles.switchContainer,
        disabled && styles.disabled,
        className,
      )}
    >
      {leftLabel && (
        <Typography
          className={cx(styles.leftLabel, {
            [styles.checked]: checked && !rightLabel,
            'dark:tw-text-green': checked && !rightLabel,
            'dark:tw-text-label-tertiary': !checked,
          })}
          variant="medium"
        >
          {leftLabel}
        </Typography>
      )}

      <div className={styles.switch}>
        <input
          ref={ref}
          checked={checked}
          className={styles.switchInput}
          disabled={disabled}
          id={id}
          onChange={onChange}
          type="checkbox"
          {...other}
        />
        <span className={styles.slider} />
      </div>

      {rightLabel && (
        <Typography
          className={cx(styles.rightLabel, {
            [styles.checked]: checked,
            'dark:tw-text-green': checked,
            'dark:tw-text-label-tertiary': !checked,
          })}
          variant="medium"
        >
          {rightLabel}
        </Typography>
      )}
    </label>
  );
});

export default Switch;
