import * as zod from 'zod';

import type { Bit, Sixteen } from '@sb/utilities';

import type { SafetyPortID } from './SafetyPort';

export const InputSafeguardPair = zod.enum([
  'DIGITAL_IN_1_2',
  'DIGITAL_IN_3_4',
  'DIGITAL_IN_5_6',
  'DIGITAL_IN_7_8',
  'DIGITAL_IN_9_10',
  'DIGITAL_IN_11_12',
  'DIGITAL_IN_13_14',
  'DIGITAL_IN_15_16',
]);

export const OutputSafeguardPair = zod.enum([
  'DIGITAL_OUT_1_2',
  'DIGITAL_OUT_3_4',
  'DIGITAL_OUT_5_6',
  'DIGITAL_OUT_7_8',
  'DIGITAL_OUT_9_10',
  'DIGITAL_OUT_11_12',
  'DIGITAL_OUT_13_14',
  'DIGITAL_OUT_15_16',
]);

export type InputSafetySafeguardPair = {
  longLabel: string;
  shortLabel: string;
  ports: [SafetyPortID, SafetyPortID];
  kind: InputSafeguardKind;
};

export const EstopInputPair: InputSafetySafeguardPair = {
  longLabel: 'E-Stop In',
  shortLabel: 'ES IN',
  ports: ['E-Stop 1', 'E-Stop 2'],
  kind: 'estop',
};

export const SafeguardInputPair: InputSafetySafeguardPair = {
  longLabel: 'Safeguard In',
  shortLabel: 'S IN',
  ports: ['Safeguard 1', 'Safeguard 2'],
  kind: 'slow',
};

export const ControlBoxEStopPair: InputSafetySafeguardPair = {
  longLabel: 'Control Box E-Stop',
  shortLabel: 'CB ES',
  ports: ['CB E-Stop 1', 'CB E-Stop 2'],
  kind: 'estop',
};

export const TabletCaseEStopPair: InputSafetySafeguardPair = {
  longLabel: 'Tablet Case E-Stop',
  shortLabel: 'TC ES',
  ports: ['Tablet E-Stop 1', 'Tablet E-Stop 2'],
  kind: 'estop',
};

export const SAFETY_SAFEGUARD_PAIRS: InputSafetySafeguardPair[] = [
  EstopInputPair,
  SafeguardInputPair,
  ControlBoxEStopPair,
  TabletCaseEStopPair,
];

export type InputSafeguardPair = zod.infer<typeof InputSafeguardPair>;

export type OutputSafeguardPair = zod.infer<typeof OutputSafeguardPair>;

export const INPUT_SAFEGUARD_PAIR_PORTS: Record<
  InputSafeguardPair,
  [number, number]
> = {
  DIGITAL_IN_1_2: [1, 2],
  DIGITAL_IN_3_4: [3, 4],
  DIGITAL_IN_5_6: [5, 6],
  DIGITAL_IN_7_8: [7, 8],
  DIGITAL_IN_9_10: [9, 10],
  DIGITAL_IN_11_12: [11, 12],
  DIGITAL_IN_13_14: [13, 14],
  DIGITAL_IN_15_16: [15, 16],
};

export const OUTPUT_SAFEGUARD_PAIR_PORTS: Record<
  OutputSafeguardPair,
  [number, number]
> = {
  DIGITAL_OUT_1_2: [1, 2],
  DIGITAL_OUT_3_4: [3, 4],
  DIGITAL_OUT_5_6: [5, 6],
  DIGITAL_OUT_7_8: [7, 8],
  DIGITAL_OUT_9_10: [9, 10],
  DIGITAL_OUT_11_12: [11, 12],
  DIGITAL_OUT_13_14: [13, 14],
  DIGITAL_OUT_15_16: [15, 16],
};

export const InputSafeguardKind = zod.enum([
  'none',
  'slow',
  'estop',
  'pauselow',
  'pausehigh',
  'reset',
]);

export type InputSafeguardKind = zod.infer<typeof InputSafeguardKind>;

export const OutputSafeguardKind = zod.enum([
  'none',
  'isestop',
  'isslow',
  'isfullspeed',
  'ismoving',
  'isstill',
]);

export type OutputSafeguardKind = zod.infer<typeof OutputSafeguardKind>;

export const INPUT_SAFEGUARD_KIND_NAMES: Record<InputSafeguardKind, string> = {
  none: 'Unassigned',
  slow: 'Slow Speed',
  estop: 'E-Stop',
  pauselow: 'Pause on low',
  pausehigh: 'Pause on high',
  reset: 'Reset Safeguards',
};

export const OUTPUT_SAFEGUARD_KIND_NAMES: Record<OutputSafeguardKind, string> =
  {
    none: 'Unassigned',
    isestop: 'Is E-Stop',
    isslow: 'Slow Speed',
    isfullspeed: 'Full Speed',
    ismoving: 'Moving',
    isstill: 'Still',
  };

export const InputSafeguardRule = zod.object({
  kind: InputSafeguardKind,
  pair: InputSafeguardPair,
  isAutoReset: zod.boolean(),
});

export const OutputSafeguardRule = zod.object({
  kind: OutputSafeguardKind,
  pair: OutputSafeguardPair,
});

export type InputSafeguardRule = zod.infer<typeof InputSafeguardRule>;
export type OutputSafeguardRule = zod.infer<typeof OutputSafeguardRule>;

// TODO: move this to firmware-interface lib (Connor is currently working on this)
export function safeguardRuleIsTriggeredBy(
  rule: InputSafeguardRule,
  digitalIn: Sixteen<Bit>,
): boolean {
  const [number1, number2] = INPUT_SAFEGUARD_PAIR_PORTS[rule.pair];

  const port1 = digitalIn[number1 - 1];
  const port2 = digitalIn[number2 - 1];

  switch (rule.kind) {
    case 'none':
      return false;
    case 'slow': // slow on low
    case 'estop': // estop on low
    case 'pauselow':
      return !port1 || !port2;
    case 'pausehigh':
      return Boolean(port1) || Boolean(port2);
    case 'reset': // reset if both are high
      return Boolean(port1 && port2);
  }
}

// TODO: move this to firmware-interface lib (Connor is currently working on this)
export function parseInputSafeguardRuleFromCAN(
  payload: Uint8Array,
): InputSafeguardRule {
  const pair: InputSafeguardPair = (() => {
    switch (payload[0]) {
      case 0:
        return 'DIGITAL_IN_1_2';
      case 1:
        return 'DIGITAL_IN_3_4';
      case 2:
        return 'DIGITAL_IN_5_6';
      case 3:
        return 'DIGITAL_IN_7_8';
      case 4:
        return 'DIGITAL_IN_9_10';
      case 5:
        return 'DIGITAL_IN_11_12';
      case 6:
        return 'DIGITAL_IN_13_14';
      case 7:
        return 'DIGITAL_IN_15_16';
    }

    throw new Error(`Invalid safeguard pair: ${payload[1]}`);
  })();

  const kind: InputSafeguardKind = (() => {
    switch (payload[1]) {
      case 0:
        return 'none';
      case 1:
        return 'slow';
      case 2:
        return 'estop';
      case 3:
        return 'pauselow';
      case 4:
        return 'pausehigh';
      case 5:
        return 'reset';
    }

    throw new Error(`Invalid safeguard kind: ${payload[0]}`);
  })();

  const isAutoReset = Boolean(payload[2]);

  return {
    kind,
    pair,
    isAutoReset,
  };
}

export const SafeguardState = zod.enum(['slowSpeed', 'fullSpeed', 'eStop']);

export type SafeguardState = zod.infer<typeof SafeguardState>;

export const SAFEGUARD_STATE_NAMES: Record<SafeguardState, string> = {
  fullSpeed: 'Full Speed',
  slowSpeed: 'Slow Speed',
  eStop: 'E-Stop',
};

export const EstopHealthCheckState = zod.enum([
  'idle',
  'failed',
  'runningMain',
  'runningSafety',
  'runningWaiting',
]);

export type EstopHealthCheckState = zod.infer<typeof EstopHealthCheckState>;
