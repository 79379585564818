import { z } from 'zod';

import { CartesianPose } from '@sb/geometry';

import {
  COMPUTEBOX_HOST_DEFAULT,
  ONROBOT_TCP_PORT_DEFAULT,
} from '../../shared/constants';
import {
  OnRobotQuickChangerCompatibleState,
  OnRobotQuickChangerCompatibleGripperConfiguration,
  OnRobotQuickChangerCompatibleCommand,
} from '../../shared/OnRobotQuickChanger';
import { OR_HEX_E_DEFAULT_TOOLTIP_TRANSFORM } from '../constants';

export const OnRobotHexEState = z.object({
  kind: z.literal('OnRobotHexE'),
  isConnected: z.boolean().default(false),
  isBusy: z.boolean().default(false),
  error: z.string().optional(),

  // Force in Newtons
  forceX: z.number().default(0),
  forceY: z.number().default(0),
  forceZ: z.number().default(0),

  // Torque in Newtons-meters
  torqueX: z.number().default(0),
  torqueY: z.number().default(0),
  torqueZ: z.number().default(0),

  primary: OnRobotQuickChangerCompatibleState.optional(),
});

export type OnRobotHexEState = z.infer<typeof OnRobotHexEState>;

export const OnRobotHexEConfiguration = z.object({
  kind: z.literal('OnRobotHexE'),
  name: z.string().default('OnRobot HEX-E'),
  primary: OnRobotQuickChangerCompatibleGripperConfiguration,

  connectionOptions: z.object({
    host: z.string().default(COMPUTEBOX_HOST_DEFAULT),
    port: z.number().default(502),
    unitID: z.literal(0x40).default(0x40),
  }),

  tcpTransform: CartesianPose.default(OR_HEX_E_DEFAULT_TOOLTIP_TRANSFORM),
});

export type OnRobotHexEConfiguration = z.infer<typeof OnRobotHexEConfiguration>;

export const ON_ROBOT_HEX_E_CONFIGURATION_DEFAULT: OnRobotHexEConfiguration = {
  kind: 'OnRobotHexE',
  name: 'OnRobot HEX-E',
  primary: {
    kind: 'NoGripper',
    name: 'No gripper',
  },
  connectionOptions: {
    host: COMPUTEBOX_HOST_DEFAULT,
    port: ONROBOT_TCP_PORT_DEFAULT,
    unitID: 0x40,
  },
  tcpTransform: OR_HEX_E_DEFAULT_TOOLTIP_TRANSFORM,
};

export const OnRobotHexEDirectCommands = z.union([
  z.literal('zero'),
  z.literal('unzero'),
]);

export type OnRobotHexEDirectCommands = z.infer<
  typeof OnRobotHexEDirectCommands
>;

export const OnRobotHexECommand = z.object({
  kind: z.literal('OnRobotHexECommand'),
  command: z.union([
    OnRobotQuickChangerCompatibleCommand,
    OnRobotHexEDirectCommands,
  ]),
});

export type OnRobotHexECommand = z.infer<typeof OnRobotHexECommand>;
