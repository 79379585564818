import * as zod from 'zod';

const LEDStateSchema = zod.enum([
  'Idle',
  'MovingFast',
  'MovingSlow',
  'Failure',
  'Antigravity',
  'AntigravitySlow',
  'Recovering',
  'BrakesEngaged',
]);

export type LEDState = zod.infer<typeof LEDStateSchema>;
