import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  DEFAULT_MAX_JOINT_ACCELERATIONS,
} from '@sb/motion-planning';
import { six } from '@sb/utilities';

import type { SpeedProfile } from './speed-profile';

// Used as a reference to generate a set of maximum accelerations
//
// Table generated from:
// https://docs.google.com/spreadsheets/d/15iyLvSYFerMMTYUmEb1Vfv26YewoUGukPU4_ziaKg-U/edit#gid=0
// which is a spreadsheet for calculating various constraints based on qualities of
// the hardware.
//
// Index is the payload in kilograms
const PAYLOAD_TO_ACCELERATIONS_TABLE = [
  { shoulder: 19.696, elbow: 46.188, wrist: 726.067 },
  { shoulder: 16.247, elbow: 36.199, wrist: 328.705 },
  { shoulder: 13.597, elbow: 29.138, wrist: 204.847 },
  { shoulder: 11.498, elbow: 23.883, wrist: 144.412 },
  { shoulder: 9.793, elbow: 19.819, wrist: 108.616 },
  { shoulder: 8.382, elbow: 16.582, wrist: 84.943 },
  { shoulder: 7.194, elbow: 13.944, wrist: 68.125 },
  { shoulder: 6.18, elbow: 11.751, wrist: 55.562 },
  { shoulder: 5.305, elbow: 9.901, wrist: 45.821 },
  { shoulder: 4.542, elbow: 8.319, wrist: 38.046 },
  { shoulder: 3.871, elbow: 6.95, wrist: 31.697 },
  { shoulder: 3.275, elbow: 5.754, wrist: 26.415 },
  { shoulder: 2.744, elbow: 4.7, wrist: 21.951 },
  { shoulder: 2.267, elbow: 3.765, wrist: 18.129 },
  { shoulder: 1.836, elbow: 2.928, wrist: 14.82 },
  { shoulder: 1.445, elbow: 2.177, wrist: 11.927 },
  { shoulder: 1.088, elbow: 1.497, wrist: 9.377 },
  { shoulder: 0.762, elbow: 0.88, wrist: 7.111 },
  { shoulder: 0.462, elbow: 0.317, wrist: 5.085 },
];

export const payloadMassToMaxAccelerations = (massKG: number) => {
  // - find the proper index for the table above.
  // - err on the higher side
  // - if payload is negative, default to 0
  const index = Math.max(0, Math.ceil(massKG));

  if (index > PAYLOAD_TO_ACCELERATIONS_TABLE.length - 1) {
    throw new Error(
      `The robot cannot handle a ${massKG}kg payload (max payload: ${PAYLOAD_TO_ACCELERATIONS_TABLE.length}kg`,
    );
  }

  // look up the relevant accelerations in the table above
  const entry = PAYLOAD_TO_ACCELERATIONS_TABLE[index];

  const { shoulder, elbow, wrist } = entry;
  const accelerations = [shoulder, shoulder, elbow, elbow, wrist, wrist];

  // limit them to the software absolute max
  // TODO: Or should the software absolute max be set to payloadMassToMaxAccelerations(0)?
  // or is there some other way we should limit it?
  for (let jj = 0; jj < accelerations.length; jj += 1) {
    accelerations[jj] = Math.min(
      accelerations[jj],
      ABSOLUTE_MAX_JOINT_ACCELERATIONS[jj],
    );
  }

  return accelerations;
};

export const STILL_GOING_CHECK_INTERVAL_MS = 100;

// Used to check if the robot is station keeping by monitoring
// moving joint positions.
export const HOLD_STEADY_POSITION_EPSILON = 0.01;

export const VALID_TOOL_DIRECTIONS_CHECK_INTERVAL_MS = 1000;

export const CHECK_COLLISIONS_INTERVAL_MS = 100;

export const RELATIVE_START_POSITION_TOLERANCE_RADIANS = 0.05;

export const ACTUATE_DEVICE_RETRY_TIMEOUT_MS = 75_000;

export const MANUAL_RECOVERY_SPEED_PROFILE: SpeedProfile = {
  maxJointSpeeds: six(0.1),
  maxTooltipSpeed: 0.1,
  maxTooltipAcceleration: null,
  maxJointAccelerations: DEFAULT_MAX_JOINT_ACCELERATIONS,
  // MANUAL_RECOVERY_SPEED_PROFILE uses scaling factor instead of
  // velocity and acceleration scaling because r2cb uses a
  // a hardcoded value for velocity and acceleration scaling=1.0
  // to speed up motion planning time. The scaling factor is then
  // used to slow down the motion at execution time.
  baseAccelerationScaling: 1.0,
  baseVelocityScaling: 1.0,
  scalingFactor: 0.1,
};

// used for automated self-recovery
export const RECOVERY_SPEED_PROFILE: SpeedProfile = {
  maxJointSpeeds: six(0.1),
  maxTooltipSpeed: 0.1,
  maxTooltipAcceleration: null,
  maxJointAccelerations: DEFAULT_MAX_JOINT_ACCELERATIONS,
  baseAccelerationScaling: 0.02,
  baseVelocityScaling: 0.02,
  scalingFactor: 1.0,
};

export const UNBOXING_SPEED_PROFILE: SpeedProfile = {
  maxJointSpeeds: six(0.1),
  maxTooltipSpeed: 0.1,
  maxTooltipAcceleration: null,
  maxJointAccelerations: DEFAULT_MAX_JOINT_ACCELERATIONS,
  baseAccelerationScaling: 0.06,
  baseVelocityScaling: 0.06,
  scalingFactor: 1.0,
};
