// Use namespaced logging
import { makeNamespacedLog } from '@sb/log';

import type {
  ClientOptions,
  ServiceInfo,
  DefaultService,
  MessageFns,
} from './auto_generated/messenger/messenger';
import {
  PingResponse,
  ServiceInfoResponse,
  PingRequest,
  ServiceInfoRequest,
  DefaultRequests,
} from './auto_generated/messenger/messenger';
import {
  RegisterRequest,
  RegistryRequests,
  protobufPackage as Registry,
  UnregisterRequest,
  UnregisterResponse,
  LiveServicesRequest,
  LiveServicesResponse,
  RegisterResponse,
} from './auto_generated/messenger/registry_service';
import type { HandlerConfig } from './request_handler';
import { RequestHandler } from './request_handler';
import { RequestSender } from './request_sender';
import { TimeoutError } from './utils/TimeoutError';

const log = makeNamespacedLog('messenger-node');

/**
 * MessengerNode - Client for direct service-to-service communication
 * Supports request-reply pattern
 */
export class MessengerNode implements DefaultService {
  // Client identification
  protected serviceKey: string;

  protected port: number = 0; // Will be assigned by registry after registration

  protected requestHandler: RequestHandler;

  protected requestSender: RequestSender;

  protected host: string;

  protected registryPort: number = 5555; // Default registry port

  protected registryEndpoint: string;

  protected debug: boolean = false;

  // Service registry (cache of other services)
  protected serviceRegistry: Map<string, ServiceInfo> = new Map();

  // State
  protected running: boolean = false;

  protected registered: boolean = false;

  private registrationAttempts: number = 0;

  /**
   * Create a new MessengerNode
   * @param serviceKey - Unique identifier for this service
   * @param options - Optional configuration parameters
   */
  public constructor(serviceKey: string, options: ClientOptions = {}) {
    this.serviceKey = serviceKey;
    this.host = options.host || '127.0.0.1';
    this.registryEndpoint = `${this.host}:${this.registryPort}`;
    this.requestSender = new RequestSender(this.serviceKey);
    this.requestHandler = new RequestHandler(this.serviceKey, this.host);

    this.serviceRegistry.set(Registry, {
      serviceKey: Registry,
      host: this.host,
      port: this.registryPort,
      messageTypes: [],
    });

    this.registerDefaultHandlers();
  }

  public Ping(_: PingRequest): Promise<PingResponse> {
    return Promise.resolve({
      pong: true,
    });
  }

  public GetServiceInfo(_: ServiceInfoRequest): Promise<ServiceInfoResponse> {
    return Promise.resolve({
      serviceInfo: {
        serviceKey: this.serviceKey,
        host: this.host,
        port: this.port,
        messageTypes: this.requestHandler?.getRequestTypes() || [],
      },
    });
  }

  public getConnectionStatus(): boolean {
    return this.running && this.registered;
  }

  /**
   * Register with the registry with retry
   * @private
   * @returns A promise that resolves to true if registration was successful, false otherwise
   * @throws {Error} When maximum registration attempts are reached
   */
  private async registerWithRetry(): Promise<boolean> {
    if (this.debug) {
      log.info('client.registering', 'Registering with registry');
    }

    this.registrationAttempts = this.registrationAttempts || 0;

    while (this.running) {
      try {
        const registerRequest: RegisterRequest = {
          serviceKey: this.serviceKey,
          host: this.host,
          messageTypes: [],
        };

        const registerResponse = await this.request(
          Registry,
          RegistryRequests[RegistryRequests.RegisterClient],
          registerRequest,
          RegisterRequest,
          RegisterResponse,
        );

        if (!registerResponse || !registerResponse.success) {
          throw new Error(registerResponse?.error || 'Registration failed');
        }

        if (!registerResponse.port) {
          throw new Error('registry did not assign a port');
        }

        this.port = registerResponse.port;
        this.registered = true;
        this.registrationAttempts = 0;

        if (this.debug) {
          log.info(
            'client.registered',
            `Successfully registered with registry`,
          );
        }

        return true;
      } catch (error) {
        this.registrationAttempts += 1;

        if (this.debug) {
          log.error(
            'client.registration-error',
            `Registration attempt ${this.registrationAttempts} failed: ${error instanceof Error ? error.message : String(error)}`,
          );
        }

        if (this.running) {
          const retryDelay = 5000;

          if (this.debug) {
            log.info(
              'client.registration-retry',
              `Will retry registration in ${retryDelay}ms`,
            );
          }

          if (this.registrationAttempts % 50 === 0) {
            log.info(
              'client.registration-attempt',
              `Unable to connect to service hub registry, retrying... (attempt ${this.registrationAttempts})`,
            );
          }

          // Wait for the delay period before continuing the loop
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
      }
    }

    return false;
  }

  protected registerDefaultHandlers(): void {
    if (!this.requestHandler) {
      throw new Error('Request handler not initialized');
    }

    this.requestHandler.onRequest(DefaultRequests[DefaultRequests.Ping], {
      handler: this.Ping.bind(this),
      requestType: PingRequest,
      responseType: PingResponse,
    });

    this.requestHandler.onRequest(
      DefaultRequests[DefaultRequests.GetServiceInfo],
      {
        handler: this.GetServiceInfo.bind(this),
        requestType: ServiceInfoRequest,
        responseType: ServiceInfoResponse,
      },
    );
  }

  /**
   * Start the client
   * @returns A promise that resolves to true if the client was started successfully
   * @throws {Error} When registration fails
   * @throws {Error} When socket binding fails
   */
  public async start(): Promise<boolean> {
    if (this.running) {
      return true;
    }

    try {
      this.running = true;
      this.requestSender.start();

      // First try to register with the registry to get a port
      const registered = await this.registerWithRetry();

      if (!registered) {
        throw new Error('Registration failed');
      }

      this.requestHandler.start(this.port);

      await this.getLiveServices(true);

      return true;
    } catch (error) {
      log.error(
        'client.start-error',
        `Error starting client: ${error instanceof Error ? error.message : String(error)}`,
      );

      // Clean up any partially initialized resources
      this.running = false;

      this.requestHandler?.stop();
      throw error;
    }
  }

  /**
   * Stop the client
   * @returns A promise that resolves when the client has been stopped
   */
  public async stop(): Promise<void> {
    if (!this.running) {
      return;
    }

    this.requestHandler?.stop();

    // Unregister from registry if we were registered
    if (this.registered) {
      try {
        const unregisterResponse = await this.request(
          Registry,
          RegistryRequests[RegistryRequests.UnregisterClient],
          {
            serviceKey: this.serviceKey,
          },
          UnregisterRequest,
          UnregisterResponse,
        );

        if (!unregisterResponse || !unregisterResponse.success) {
          log.error(
            'client.unregister-error',
            `Error unregistering: ${unregisterResponse?.error || 'Unregistration failed'}`,
          );
        } else if (this.debug) {
          log.info(
            'client.unregistered',
            'Successfully unregistered from registry',
          );
        }
      } catch (error) {
        log.error(
          'client.unregister-error',
          `Error unregistering: ${error instanceof Error ? error.message : String(error)}`,
        );
      }
    }

    this.registered = false;
    this.requestSender.stop();
    this.running = false;
  }

  /**
   * Get live services from registry with optional cache refresh
   * @param refreshCache - Whether to force a refresh of the service registry from the registry
   * @returns A promise resolving to an array of ServiceInfo objects
   * @throws {Error} When client is not running
   * @throws {Error} When registry communication fails and no cached services exist
   */
  public async getLiveServices(
    refreshCache: boolean = false,
  ): Promise<ServiceInfo[]> {
    if (!this.running) {
      throw new Error('Client is not running');
    }

    try {
      // If we have cached services and don't need to refresh, return them
      if (!refreshCache) {
        return Array.from(this.serviceRegistry.values());
      }

      // Request live services from registry
      const liveServicesResponse = await this.request(
        Registry,
        RegistryRequests[RegistryRequests.GetLiveServices],
        {},
        LiveServicesRequest,
        LiveServicesResponse,
      );

      if (
        !liveServicesResponse ||
        !liveServicesResponse.services ||
        !Array.isArray(liveServicesResponse.services)
      ) {
        return [];
      }

      // Update cache with registry response
      this.serviceRegistry = new Map();

      // Add other services
      for (const service of liveServicesResponse.services) {
        this.serviceRegistry.set(`${service.serviceKey}`, service);
      }

      return liveServicesResponse.services;
    } catch (error) {
      log.error(
        'client.get-live-services-error',
        `Error getting live services: ${error instanceof Error ? error.message : String(error)}`,
      );

      // Always throw error to avoid hiding errors from consumers
      throw error;
    }
  }

  public async request(
    service: string,
    method: string,
    data: any,
    requestType: MessageFns<any>,
    responseType: MessageFns<any>,
  ): Promise<any> {
    if (!this.running) {
      throw new Error('MessengerNode is not running');
    }

    let serviceInfo = this.serviceRegistry.get(service);

    if (!serviceInfo) {
      log.error(
        'client.request-error',
        `Service not found: ${service}. Refreshing service registry`,
      );

      await this.getLiveServices(true);

      serviceInfo = this.serviceRegistry.get(service);

      if (!serviceInfo) {
        throw new Error(`Service not found: ${service}`);
      }
    }

    const endpoint = `${serviceInfo.host}:${serviceInfo.port}`;

    try {
      return await this.requestSender.sendRequest(
        endpoint,
        method,
        data,
        requestType,
        responseType,
      );
    } catch (error) {
      // If it's a TimeoutError, refresh the service registry before re-throwing
      if (error instanceof TimeoutError) {
        log.warn(
          'client.request-timeout',
          `Request to ${service}.${method} timed out. Refreshing service registry.`,
        );

        try {
          // Refresh the service registry
          await this.getLiveServices(true);

          log.info(
            'client.registry-refreshed',
            `Service registry refreshed after timeout on ${service}.${method}`,
          );
        } catch (refreshError) {
          log.error(
            'client.refresh-error',
            `Error refreshing services after timeout: ${
              refreshError instanceof Error
                ? refreshError.message
                : String(refreshError)
            }`,
          );
        }
      }

      // Always re-throw the original error
      throw error;
    }
  }

  /**
   * Register a request handler
   * @param messageType - The type of message to handle
   * @param handler - Function to execute when a message of this type is received
   */
  public onRequest<RequestType = any, ResponseType = any>(
    messageType: string,
    handler: HandlerConfig<RequestType, ResponseType>,
  ): void {
    if (messageType in DefaultRequests) {
      throw new Error(`Message type is a default message: ${messageType}`);
    }

    this.requestHandler.onRequest(messageType, handler);
  }

  /**
   * Remove a request handler
   * @param messageType - The type of message handler to remove
   */
  public offRequest(messageType: string): void {
    if (messageType in DefaultRequests) {
      throw new Error(`Message type is a default message: ${messageType}`);
    }

    this.requestHandler.offRequest(messageType);
  }

  public getServiceRegistry(): Map<string, ServiceInfo> {
    return this.serviceRegistry;
  }
}
