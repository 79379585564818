import * as zod from 'zod';

import type { CustomEquipmentActionConfiguration } from '@sb/integrations/types/customEquipmentTypes';
import { SetIOStep } from '@sb/remote-control/step/setIO';
import type { Robot } from '@sb/remote-control/types';
import { ActionRequiredError, Step } from '@sb/remote-control/types';

import { getActionByID, getDevice } from '../toRoutineRunnerHelpers';

export namespace CNCOperateAutodoorStepDatabase {
  export const name = 'Operate autodoor';
  export const description = 'Operate the autodoor on a CNC machine';
  export const deviceKinds = ['CNCMachine'] as const;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'CNCOperateAutodoor';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    deviceID: zod.string().default(''),
    actionID: zod.string().default(''),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validateSafetyOutputUsage = (
    ioOutputs: Robot.IOPort[],
    action: CustomEquipmentActionConfiguration,
  ) => {
    const usedOutputs = ioOutputs.filter((output) => {
      if (
        output.kind !== 'Output' &&
        output.kind !== 'Relay' &&
        output.kind !== 'FlangeOutput'
      ) {
        return false;
      }

      const actionOutputs = action?.actionOutputs;

      if (actionOutputs == null || actionOutputs.kind !== 'controlBoxIO') {
        return false;
      }

      return actionOutputs.outputs.some(
        (actionOutput) =>
          actionOutput.ioPort ===
          SetIOStep.getPortLabel({
            kind: output.kind,
            port: output.port,
            level: 'low',
          }),
      );
    });

    const usedSafetyIOs = usedOutputs.filter(
      (output) => output.assignedTo?.kind === 'safetyIO',
    );

    if (usedSafetyIOs.length > 0) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Safety I/O outputs cannot be set using actions',
        fieldId: 'outputs',
      });
    }
  };

  export const validator: Step.Validator = ({
    stepConfiguration,
    ioOutputs,
    equipment,
  }) => {
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    const machineConfig = getDevice(args.deviceID, equipment);

    const action = getActionByID(machineConfig.autodoor, args.actionID);

    if (action == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Action is required',
        fieldId: 'actionID',
      });
    }

    validateSafetyOutputUsage(ioOutputs, action);
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const machineConfig = getDevice(args.deviceID, equipment);

    getActionByID(machineConfig.autodoor, args.actionID);

    return {
      ...stepData,
      stepKind: 'CNCOperateAutodoor',
      args,
    };
  };
}

CNCOperateAutodoorStepDatabase satisfies Step.StepKindInfo;
