import * as zod from 'zod';

export default zod.object({
  inferenceMachineEndpoint: zod.string(),
  modelPath: zod.string(),
  timeout: zod.number(),
  secondaryRobotId: zod.string(),
  isBimanual: zod.boolean(),
  isPrimary: zod.boolean(),
});
