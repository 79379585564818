import * as zod from 'zod';

import { MotionKind } from '@sb/motion-planning';
import { TCPOptionOrAuto } from '@sb/motion-planning/TCPOffsetOption';
import { BlendConfig } from '@sb/motion-planning/Waypoint';
import { Expression, Space } from '@sb/routine-runner/types';

import { SpeedProfile } from '../../speed-profile';

export default zod.object({
  target: zod.union([
    Space.Position,
    zod.object({
      positionListID: zod.string(),
      positionListIndex: zod.number().int().nonnegative().optional(),
    }),
    zod.object({
      expression: Expression,
    }),
  ]),
  shouldMatchJointAngles: zod.boolean(),
  motionKind: MotionKind,
  stopHere: zod.boolean(),
  blendConfig: BlendConfig,
  useParentBlendConfig: zod.boolean(),
  // Since per-waypoint speeed profiles are not yet supported, these arguements are ignored.
  // I'm keeping them here for now to avoid breaking existing routines.
  // see https://github.com/standardbots/sb/pull/9257
  speedProfile: SpeedProfile,
  useParentSpeedProfile: zod.boolean(),
  // TCP offset option for this specific waypoint
  tcpOption: TCPOptionOrAuto,
});
