import * as zod from 'zod';

import { BlendConfig } from '@sb/motion-planning/Waypoint';

import { SpeedProfile } from '../../speed-profile';

export default zod.object({
  speedProfile: SpeedProfile,
  blendConfig: BlendConfig,
});
