import * as zod from 'zod';

const HostCommandName = zod.enum([
  'restartCamera',
  'echoTest',
  'services_restart',
  'configureModbusRTUUart',
  'installFirmware',
  'factoryReset',
  'powerCycle',
  'toggleDHCPServer',
  'checkCPU',
  'mountUSB',
  'umountUSB',
  'listDrivesUSB',
  'codeBlocks',
  'controlBoxTemp',
  'botctlUtils',
]);

const RestartCameraCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.restartCamera),
  output: zod.string().optional(),
});

const EchoTestCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.echoTest),
  output: zod.string().optional(),
});

const ServiceRestart = zod.object({
  command: zod.literal(HostCommandName.Enum.services_restart),
  service: zod
    .enum([
      'simulator-bot',
      'metrics-bot',
      'can-proxy-bot',
      'postgres-bot',
      'ngrok-bot',
      'updater-bot',
      'camera-bot',
      'mini-arm-bot',
      'teleop-bot',
      'ros-proxy-bot',
      'feathers-bot',
      'remote-control',
      'motion-planner-bot',
      'arm-control-bot',
      'botman',
      'vision-bot',
      'robby-server',
      'kalibrate-bot',
      'assembly-script-bot',
      'service-hub-bot',
      'all',
    ])
    .default('all'),
  output: zod.string().optional(),
});

const ModbusRTUUartConfig = zod.object({
  baudRate: zod.number(),
  parity: zod.enum(['none', 'even', 'odd']),
  stopBits: zod.enum(['ZeroPointFive', 'One', 'OnePointFive', 'Two']),
  output: zod.string().optional(),
});

const ConfigureModbusRTUUart = zod.object({
  command: zod.literal(HostCommandName.Enum.configureModbusRTUUart),
  configureModbusRTUUart: ModbusRTUUartConfig,
  output: zod.string().optional(),
});

// Restart or stop the DHCP server
const ToggleDHCPServer = zod.object({
  command: zod.literal(HostCommandName.Enum.toggleDHCPServer),
  enable: zod.boolean(),
  output: zod.string().optional(),
});

export type ModbusRTUUartConfig = zod.infer<typeof ModbusRTUUartConfig>;

const InstallFirmwareCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.installFirmware),
  installFirmware: zod.object({
    joints: zod.array(zod.number()),
    force: zod.boolean().optional(),
    skipSoftwareShutdown: zod.boolean().optional(),
    build: zod.string().optional(),
    stlink: zod.boolean().optional(),
    firmwareType: zod.string().optional(),
    variant: zod.string().default('default').optional(),
  }),
  output: zod.string().optional(),
});

const FactoryReset = zod.object({
  command: zod.literal(HostCommandName.Enum.factoryReset),
  output: zod.string().optional(),
});

const PowerCycleCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.powerCycle),
  restartJoints: zod.object({
    joints: zod.array(zod.number()),
  }),
  output: zod.string().optional(),
});

const CheckCPUUsage = zod.object({
  command: zod.literal(HostCommandName.Enum.checkCPU),
  output: zod.string().optional(),
});

const MountUSB = zod.object({
  command: zod.literal(HostCommandName.Enum.mountUSB),
  name: zod.string(),
  output: zod.string().optional(),
});

const UmountUSB = zod.object({
  command: zod.literal(HostCommandName.Enum.umountUSB),
  name: zod.string(),
  output: zod.string().optional(),
});

const ListDrivesUSB = zod.object({
  command: zod.literal(HostCommandName.Enum.listDrivesUSB),
  output: zod.string().optional(),
});

const CodeBlocksCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.codeBlocks),
  codeBlockPath: zod.string(),
  routineId: zod.string(),
  robotToken: zod.string(),
  contextKind: zod.enum(['code-block-step', 'expression']),
  robotKind: zod.enum(['simulated', 'live']),
  output: zod.string().optional(),
});

const ControlBoxTempCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.controlBoxTemp),

  /**
   * Output is Pseudo-JSON.
   *
   * Why not JSON? No combination of `\\"`, `\\\\"`, `\"` survives the command processing/escaping. Always get back to something like this.
   *
   * If has CPU temps: (Then actual temp is 46 deg. celcius.)
   * {x86_pkg_temp:46000,acpitz:27800,iwlwifi_1:43000}
   *
   * If not: (E.g. if on VM)
   * {}
   */
  output: zod.string().optional(),
});

const BotctlUtilsCommand = zod.object({
  command: zod.literal(HostCommandName.Enum.botctlUtils),
  type: zod.enum(['shutdown']),
  params: zod.string().optional(),
  output: zod.string().optional(),
});

const HostCommandData = zod.discriminatedUnion('command', [
  RestartCameraCommand,
  EchoTestCommand,
  InstallFirmwareCommand,
  PowerCycleCommand,
  FactoryReset,
  ServiceRestart,
  ConfigureModbusRTUUart,
  ToggleDHCPServer,
  CheckCPUUsage,
  MountUSB,
  UmountUSB,
  ListDrivesUSB,
  CodeBlocksCommand,
  ControlBoxTempCommand,
  BotctlUtilsCommand,
]);

export type HostCommandName = zod.infer<typeof HostCommandName>;
export type HostCommandData = zod.infer<typeof HostCommandData>;
