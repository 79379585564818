import { ZERO_ROTATION } from '@sb/geometry';

export const ORHEXE_TARGET_ADDRESS = 0x40;

export enum Register {
  // Write-only
  ZERO = 0x0000, // Zero force and torque values

  // Read-only
  STATUS = 0x0101, // 0 when no error
  FORCE_X = 0x0103, // 1/10 N
  FORCE_Y = 0x0104, // 1/10 N
  FORCE_Z = 0x0105, // 1/10 N
  TORQUE_X = 0x0106, // 1/100 Nm
  TORQUE_Y = 0x0107, // 1/100 Nm
  TORQUE_Z = 0x0108, // 1/100 Nm
}

export enum OR_HEX_E_ZERO_COMMAND {
  UNZERO = 0x0000,
  ZERO = 0x0001,
}

// The tool face is projected out 50mm from the flange
export const OR_HEX_E_DEFAULT_TOOLTIP_TRANSFORM = {
  ...ZERO_ROTATION,
  x: 0,
  y: 0,
  z: 0.05,
};
