import * as zod from 'zod';

import { TCPOptionOrAuto } from '@sb/motion-planning';
import {
  ActionRequiredError,
  MotionSpeed,
  SpeedLimitOption,
  Step,
} from '@sb/remote-control/types';
import type { SpeedProfile } from '@sb/routine-runner/speed-profile';
import type { Target as ArcTarget } from '@sb/routine-runner/Step/Arc/Arguments';
import { Expression, Space } from '@sb/routine-runner/types';

import { calculateSpeedProfileFromStepMotionSpeed } from '../util/calculateSpeedProfileFromStepMotionSpeed';
import convertJavaScriptExpressionToUser from '../util/expressions/convertJavaScriptExpressionToUser';
import { convertPythonExpression } from '../util/expressions/v2';
import { validateCodeExpression } from '../util/expressions/validateCodeExpression';
import { validateMotionSpeed } from '../util/validators';

const TargetKind = zod.union([
  zod.literal('singlePosition'),
  zod.literal('positionList'),
  zod.literal('expression'),
]);

type TargetKind = zod.infer<typeof TargetKind>;

export namespace ArcStep {
  export const name = 'Arc';
  export const description =
    'Move the robot in an arc through two positions in space';
  export const librarySection = Step.LibrarySection.Hidden;
  export const argumentKind = 'Arc';
  export const featureFlag = 'arcStep';

  const permittedParentStepKinds = ['MoveArmToV2', 'Weld'];

  export const Arguments = zod.object({
    argumentKind: zod.literal('Arc'),
    motionSpeed: zod.preprocess(
      // Per-arc speed profile is not supported yet. Leave this here for when it is.
      (val) => (val == null ? undefined : val),
      MotionSpeed.optional(),
    ),
    speedLimitOption: SpeedLimitOption.default('PARENT_DEFAULTS'),
    tcpOption: TCPOptionOrAuto.default('auto'),
    stopHere: zod.boolean().default(false),
    stopHereRaw: zod.boolean().default(false),
    moveDynamicBaseToReachPosition: zod.boolean().default(true),

    // Targets: One each for "via" and "end" points
    targetKinds: zod
      .tuple([TargetKind, TargetKind])
      .default(['singlePosition', 'singlePosition']),
    positionListIDs: zod
      .tuple([zod.string().nullable(), zod.string().nullable()])
      .default([null, null]),
    positionListIndices: zod
      .tuple([zod.number().nullable(), zod.number().nullable()])
      .default([null, null]),
    targets: zod
      .tuple([Space.Position.nullable(), Space.Position.nullable()])
      .default([null, null]),
    expressions: zod
      .tuple([Expression.nullable(), Expression.nullable()])
      .default([null, null]),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  const validateTarget = (
    positionListID: string | null,
    positionListIndex: number | null,
    target: Space.Position | null,
    targetKind: TargetKind,
    expression: Expression | null,
    spaceItems: Space.Item[],
  ) => {
    const hasTarget = !!(target?.jointAngles || target?.pose);

    if (targetKind === 'singlePosition' && !hasTarget) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Joint angles or pose must be set',
        fieldId: 'jointAngles',
      });
    }

    if (targetKind === 'positionList') {
      if (positionListID == null) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'The space item must be set',
          fieldId: 'positionListID',
        });
      }

      const space = spaceItems.find(
        (spaceItem) => spaceItem.id === positionListID,
      );

      if (space == null) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Choose a valid space item',
          fieldId: 'positionListID',
        });
      }

      if (
        positionListIndex != null && // If null, then cycling through list.
        (positionListIndex < 0 || positionListIndex >= space.positions.length) // If index is out of bounds. (NOTE: Do not check the space type. This could be an issue if the space item is changed to a non-position list. Under the hood, RoutineContext#getPositionListEntry can handle this, however, the user probably doesn't want to use an invalid/out-of-bounds index.)
      ) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Choose a valid index for the position list.',
          fieldId: 'positionListIndex',
        });
      }
    }

    if (targetKind === 'expression' && expression != null) {
      validateCodeExpression(expression, 'expression', 'Position expression');
    }
  };

  export const validator: Step.Validator = ({
    step,
    stepConfiguration,
    routine: { space },
    globalSpace,
  }) => {
    const spaceItems = [...globalSpace, ...space];
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    // Get the parent step kind
    const parentStepKind = step.parentSteps?.at(-1)?.stepKind ?? '';

    if (!permittedParentStepKinds.includes(parentStepKind)) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Arc steps may only be used inside a Move Arm or Weld step',
      });
    }

    // Validate that for Weld steps, the tcpOption must be 'auto'
    if (parentStepKind === 'Weld' && args.tcpOption !== 'auto') {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          'TCP Option must be set to "auto" when used within a Weld step',
        fieldId: 'tcpOption',
      });
    }

    // checkValidPositionConfig

    for (let i = 0; i < 2; i += 1) {
      validateTarget(
        args.positionListIDs[i],
        args.positionListIndices[i],
        args.targets[i],
        args.targetKinds[i],
        args.expressions[i],
        spaceItems,
      );
    }
  };

  const createTarget = (
    targetKind: TargetKind,
    positionListID: string | null,
    positionListIndex: number | null,
    target: Space.Position | null,
    expression: Expression | null,
  ): ArcTarget => {
    switch (targetKind) {
      case 'positionList':
        if (positionListID) {
          return {
            positionListID,
            positionListIndex: positionListIndex ?? undefined,
          };
        }

        break;

      case 'expression':
        if (expression) {
          return { expression };
        }

        break;

      default:
        if (target) {
          return target;
        }
    }

    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Target is not configured',
    });
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    baseSpeedProfile,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const {
      motionSpeed,
      speedLimitOption,
      tcpOption,
      stopHere,
      positionListIDs,
      positionListIndices,
      targets: targetsRaw,
      targetKinds,
      expressions,
    } = args;

    validateMotionSpeed(name, motionSpeed);

    const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed(
      {
        motionSpeed,
        baseSpeedProfile,
      },
    );

    const targets = positionListIDs.map((_, i) =>
      createTarget(
        targetKinds[i],
        positionListIDs[i],
        positionListIndices[i],
        targetsRaw[i],
        expressions[i],
      ),
    ) as [ArcTarget, ArcTarget];

    return {
      ...stepData,
      stepKind: 'Arc',
      args: {
        targets,
        stopHere,
        speedProfile,
        useParentSpeedProfile: speedLimitOption === 'PARENT_DEFAULTS',
        tcpOption,
      },
    };
  };

  const getTargetDescription = (
    targetKind: TargetKind,
    positionListID: string | null,
    positionListIndex: number | null,
    expression: Expression | null,
    spaceItems: Space.Item[],
  ) => {
    if (targetKind === 'singlePosition') {
      return 'manually specified position';
    }

    if (targetKind === 'positionList') {
      const space = spaceItems.find((item) => item.id === positionListID);

      if (space == null) {
        return null;
      }

      if (space.kind === 'singlePosition') {
        return space.name;
      }

      if (space.kind === 'safeHomePose') {
        return 'home pose';
      }

      if (
        space.kind === 'freeformPositionList' ||
        space.kind === 'gridPositionList'
      ) {
        if (positionListIndex == null) {
          return `the next position in ${space.name}`;
        }

        return `position ${positionListIndex + 1} in ${space.name}`;
      }

      return null;
    }

    if (targetKind === 'expression') {
      if (expression == null) {
        return null;
      }

      if (expression.kind === 'JavaScript') {
        // This looks pretty gnarly for most expressions. Probably want to allow
        // the user to build up the expression using a DSL that we can desugar
        // into JS later.
        const jsExprStr = convertJavaScriptExpressionToUser(
          expression.expression,
          { isForStepDescription: true },
        );

        return `a point based on expression ${jsExprStr}`;
      }

      if (expression.kind === 'Python') {
        const { code: pyExprStr } =
          convertPythonExpression.withRemoteControlVariables.toUserDescription(
            expression.expression,
          );

        return `a point based on expression ${pyExprStr}`;
      }

      return null;
    }

    return null;
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
    includeStepName,
    globalSpace,
  }) => {
    if (args?.argumentKind !== argumentKind || !routine) {
      return null;
    }

    const allSpaces = [...(globalSpace ?? []), ...routine.space];

    const targetDescriptions = args.targetKinds.map((_, i) =>
      getTargetDescription(
        args.targetKinds[i],
        args.positionListIDs[i],
        args.positionListIndices[i],
        args.expressions[i],
        allSpaces,
      ),
    ) as [string | null, string | null];

    if (targetDescriptions[0] == null || targetDescriptions[1] == null) {
      return null;
    }

    const parts: string[] = [];

    if (includeStepName) {
      parts.push(`${name} `);
    }

    parts.push(
      ...['through', targetDescriptions[0], 'to', targetDescriptions[1]],
    );

    return parts.join(' ');
  };
}

ArcStep satisfies Step.StepKindInfo;
