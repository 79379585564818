import { uniq } from 'lodash';

import type { RoutineVariable, Step } from '@sb/remote-control/types';
import type { MoveArmToStepVariables } from '@sb/routine-runner';

const MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE: Record<
  MoveArmToStepVariables['currentActivity'],
  string
> = {
  moving: 'Moving Arm',
  none: 'Not Moving to Position',
  paused: 'Paused',
  planning: 'Planning Motion',
  receivingPlan: 'Receiving Motion Plan',
  requestingPlan: 'Requesting Motion Plan',
};

function toEnumValues<V extends string>(
  byValue: Record<V, string>,
): RoutineVariable.EnumValue<V>[] {
  return Object.entries(byValue).map(([value, displayName]) => ({
    value: value as V,
    displayName: displayName as string,
  }));
}

const MOVE_ARM_VARIABLE_LIST: RoutineVariable.Information[] = [
  {
    variableName: 'currentActivity',
    displayName: 'Current Activity',
    variableKind: 'enum',
    values: toEnumValues(MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE),
  },
];

const ACTUATE_GRIPPER_VARIABLE_LIST: RoutineVariable.Information[] = [
  {
    variableName: 'succeeded',
    displayName: 'Succeeded',
    variableKind: 'boolean',
    values: [
      { value: true, displayName: 'Yes' },
      { value: false, displayName: 'No' },
    ],
  },
];

/**
 * All steps that support output variables.
 */
export function getStepVariableInfo(
  stepConfiguration?: Step.Configuration,
): RoutineVariable.Information[] {
  switch (stepConfiguration?.args?.argumentKind) {
    case 'ActuateGripper': {
      return ACTUATE_GRIPPER_VARIABLE_LIST;
    }

    case 'Classify': {
      const possibleResults = uniq([
        ...stepConfiguration.args.classes.map((c) => c.name),
        stepConfiguration.args.fallbackValue,
      ]);

      return [
        {
          variableName: 'latestResult',
          displayName: 'Latest result',
          variableKind: 'enum',
          values: possibleResults.map((value) => ({
            value,
            displayName: value,
          })),
        },
      ];
    }

    case 'HaasControlRegion': {
      return [
        {
          variableName: 'haasProgram',
          displayName: 'Currently Running Program',
          variableKind: 'string',
        },
        {
          variableName: 'haasStatus',
          displayName: 'Current Haas Machine Status',
          variableKind: 'string',
        },
        {
          variableName: 'haasPartCount',
          displayName: 'Haas Part Count',
          variableKind: 'number',
          minimum: 0,
        },
      ];
    }

    case 'HaasRunProgram': {
      return [
        {
          variableName: 'isProgramComplete',
          displayName: 'Machine Program Complete',
          variableKind: 'boolean',
          values: [
            { value: true, displayName: 'Complete' },
            { value: false, displayName: 'Not Complete' },
          ],
        },
      ];
    }

    case 'Locate': {
      return [
        {
          variableName: 'resultCount',
          displayName: 'Objects located count',
          variableKind: 'number',
        },
      ];
    }

    case 'Loop': {
      return [
        {
          variableName: 'currentIteration',
          displayName: 'Current Iteration',
          variableKind: 'number',
          minimum: 0,
          maximum: Infinity,
        },
      ];
    }

    case 'MoveArmTo': {
      return [
        ...MOVE_ARM_VARIABLE_LIST,
        {
          variableName: 'completedCount',
          displayName: 'Completed Count',
          variableKind: 'number',
        },
      ];
    }

    case 'PressButton':
      return MOVE_ARM_VARIABLE_LIST;

    case 'RunInBackground': {
      return [
        {
          variableName: 'isRunning',
          displayName: 'Current Activity',
          variableKind: 'boolean',
          values: [
            { value: true, displayName: 'Running' },
            { value: false, displayName: 'Not Running' },
          ],
        },
      ];
    }

    case 'WaitForConfirmation': {
      switch (stepConfiguration.args.mode) {
        case 'confirm': {
          return [
            {
              variableName: 'confirmed',
              displayName: 'Confirm',
              variableKind: 'boolean',
              values: [
                { value: true, displayName: 'Confirmed' },
                { value: false, displayName: 'Not confirmed' },
              ],
            },
          ];
        }
        case 'choice': {
          return [
            {
              variableName: 'choice',
              displayName: 'Choice',
              variableKind: 'enum',
              values: stepConfiguration.args.choices.map((choice) => ({
                value: choice,
                displayName: choice,
              })),
            },
          ];
        }
        case 'freeform': {
          return [
            {
              variableName: 'freeformInput',
              displayName: 'Freeform',
              variableKind: 'string',
            },
          ];
        }
        default:
          return [];
      }
    }

    case 'Weld': {
      return [
        {
          variableName: 'completedWeldCount',
          displayName: 'Completed Weld Count',
          variableKind: 'number',
        },
        {
          variableName: 'currentActivity',
          displayName: 'Current Activity',
          variableKind: 'enum',
          values: toEnumValues({
            approaching: 'Approaching',
            settingWeldParameters: 'Setting Weld Parameters',
            startArc: 'Start Arc',
            movingDuringWeld: 'Moving During Weld',
            craterFilling: 'Crater Filling',
            stopArc: 'Stop Arc',
            none: 'None',
          }),
        },
        {
          variableName: 'approachMovementActivity',
          displayName: 'Approach Movement Activity',
          variableKind: 'enum',
          values: toEnumValues(MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE),
        },
        {
          variableName: 'weldMovementActivity',
          displayName: 'Weld Movement Activity',
          variableKind: 'enum',
          values: toEnumValues(MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE),
        },
      ];
    }

    default:
      return [];
  }
}
