import type { ControlPanelTokenData } from '@sb/feathers-types';

import { getService } from '../utils';

export async function validateControlPanelPIN(
  pin: string,
): Promise<ControlPanelTokenData> {
  const result = await getService('controlPanelToken')().create({ pin });

  return result;
}
