import * as zod from 'zod';

import { OnRobotQuickChangerCompatibleCommand } from '../../shared/OnRobotQuickChanger/OnRobotQuickChangerCompatibleCommand';

export const OnRobotDualQuickChangerCommand = zod.object({
  kind: zod.literal('OnRobotDualQuickChangerCommand'),

  active: zod.enum(['primary', 'secondary']),

  command: OnRobotQuickChangerCompatibleCommand,
});

export type OnRobotDualQuickChangerCommand = zod.infer<
  typeof OnRobotDualQuickChangerCommand
>;
