import * as zod from 'zod';

import { TCPOptionOrAuto } from '@sb/motion-planning/TCPOffsetOption';
import { Expression, Space } from '@sb/routine-runner/types';

import { SpeedProfile } from '../../speed-profile';

export const Target = zod.union([
  Space.Position,
  zod.object({
    positionListID: zod.string(),
    positionListIndex: zod.number().int().nonnegative().optional(),
  }),
  zod.object({
    expression: Expression,
  }),
]);
export type Target = zod.infer<typeof Target>;

export default zod.object({
  targets: zod.tuple([Target, Target]),
  stopHere: zod.boolean(),
  speedProfile: SpeedProfile,
  useParentSpeedProfile: zod.boolean(),
  // TCP offset option for this specific arc
  tcpOption: TCPOptionOrAuto,
});
