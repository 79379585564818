import { CNCMachineConfiguration } from '../implementations/CNCMachine/types';
import { CustomDeviceConfiguration } from '../implementations/CustomDevice/types/CustomDeviceConfiguration';
import { CustomGripperConfiguration } from '../implementations/CustomGripper/types/CustomGripperConfiguration';
import { CustomIOConfiguration } from '../implementations/CustomIO/types/CustomIOConfiguration';
import { DHAG105145Configuration } from '../implementations/dh/instances/DHAG_105_145/Configuration';
import { DHCGI100170Configuration } from '../implementations/dh/instances/DHCGI_100_170/Configuration';
import { DHPGC30060Configuration } from '../implementations/dh/instances/DHPGC_300_60/Configuration';
import { EwellixLiftTLTConfiguration } from '../implementations/EwellixLiftTLT/types';
import { InvalidConfiguration } from '../implementations/InvalidConfiguration/types';
import { ModbusTCPServerConfiguration } from '../implementations/ModbusTCPServer/types';
import { NoGripperConfiguration } from '../implementations/NoGripper/types';
import { OnRobot2FG14Configuration } from '../implementations/onRobot/OnRobot2FG14/types';
import { OnRobot2FG7Configuration } from '../implementations/onRobot/OnRobot2FG7/types';
import { OnRobot3FG15Configuration } from '../implementations/onRobot/OnRobot3FG15/types';
import { OnRobotDualQuickChangerConfiguration } from '../implementations/onRobot/OnRobotDualQuickChanger/types';
import { OnRobotHexEConfiguration } from '../implementations/onRobot/OnRobotHexE/types';
import { OnRobotScrewdriverConfiguration } from '../implementations/onRobot/OnRobotScrewdriver/types';
import { OnRobotVGP20Configuration } from '../implementations/onRobot/OnRobotVGP20/types';
import { SchunkEGxConfiguration } from '../implementations/schunk/SchunkEGx/types/SchunkEGxConfiguration';
import { StandardBotsRO1Configuration } from '../implementations/StandardBotsRO1/types/StandardBotsRO1Configuration';
import { EsabWeldMachineConfiguration } from '../implementations/WeldMachine/EsabWeldMachine/types/EsabWeldMachineConfiguration';
import { WeldMachineConfiguration } from '../implementations/WeldMachine/generic/types/WeldMachineConfiguration';
import { MillerWeldMachineConfiguration } from '../implementations/WeldMachine/MillerWeldMachine/types/MillerWeldMachineConfiguration';
import { WristCameraConfiguration } from '../implementations/WristCamera/types';

/**
 * DeviceConfiguration is the configuration for a device.
 * Configuration can include information such as IP address, port, finger width, etc.
 *
 * Configuration is passed into the core device implementation when the device is created.
 */
export const DeviceConfigurationRegistry = [
  OnRobotDualQuickChangerConfiguration,
  OnRobotScrewdriverConfiguration,
  OnRobotVGP20Configuration,
  OnRobot2FG7Configuration,
  OnRobot2FG14Configuration,
  OnRobot3FG15Configuration,
  CustomGripperConfiguration,
  EwellixLiftTLTConfiguration,
  NoGripperConfiguration,
  CustomIOConfiguration,
  CustomDeviceConfiguration,
  InvalidConfiguration,
  ModbusTCPServerConfiguration,
  WristCameraConfiguration,
  DHAG105145Configuration,
  DHPGC30060Configuration,
  DHCGI100170Configuration,
  SchunkEGxConfiguration,
  CNCMachineConfiguration,
  StandardBotsRO1Configuration,
  WeldMachineConfiguration,
  MillerWeldMachineConfiguration,
  EsabWeldMachineConfiguration,
  OnRobotHexEConfiguration,
] as const;
