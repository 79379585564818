import { jwtDecode } from 'jwt-decode';

import { validateControlPanelPIN } from './validateControlPanelPIN';

// Key for storing JWT token in sessionStorage
const CONTROL_PANEL_AUTH_TOKEN_KEY = 'controlPanelAuthToken';

interface ControlPanelJWTPayload {
  iat: number;
  exp: number;
  isControlPanel: boolean;
  [key: string]: any;
}

/**
 * Clears the control panel authentication token
 */
export function clearControlPanelAuth(): void {
  sessionStorage.removeItem(CONTROL_PANEL_AUTH_TOKEN_KEY);
}

/**
 * Checks if the control panel access is still valid by validating the JWT token
 * @returns true if control panel access is valid, false otherwise
 */
export function isControlPanelAccessValid(): boolean {
  const token = sessionStorage.getItem(CONTROL_PANEL_AUTH_TOKEN_KEY);

  if (!token) {
    return false;
  }

  try {
    const payload = jwtDecode<ControlPanelJWTPayload>(token);
    const currentTime = Math.floor(Date.now() / 1000);

    if (!payload.exp || payload.exp < currentTime) {
      clearControlPanelAuth();

      return false;
    }

    if (!payload.isControlPanel) {
      clearControlPanelAuth();

      return false;
    }

    return true;
  } catch (error) {
    clearControlPanelAuth();

    return false;
  }
}

/**
 * Validates the PIN and stores the JWT token in sessionStorage
 * @param pin The PIN to validate
 * @returns Promise that resolves when PIN is validated
 */
export async function authenticateControlPanel(pin: string): Promise<boolean> {
  try {
    clearControlPanelAuth();

    const result = await validateControlPanelPIN(pin);

    if (!result?.accessToken) {
      return false;
    }

    sessionStorage.setItem(CONTROL_PANEL_AUTH_TOKEN_KEY, result.accessToken);

    const isValid = isControlPanelAccessValid();

    if (!isValid) {
      clearControlPanelAuth();

      return false;
    }

    return true;
  } catch (error) {
    clearControlPanelAuth();
    throw error;
  }
}
