import type { Step } from '@sb/remote-control/types';

/** Return first step in nested structure where predicate is true */
export function findStep(
  steps: Step.ConvertedSummary[],
  predicate: (step: Step.ConvertedSummary) => boolean | undefined,
): Step.ConvertedSummary | undefined {
  for (const step of steps) {
    if (predicate(step)) {
      return step;
    }

    if (step.steps.length > 0) {
      const childResult = findStep(step.steps, predicate);

      if (childResult) {
        return childResult;
      }
    }
  }

  return undefined;
}
