import * as zod from 'zod';

import { DBBackupData } from './dbBackup';
import { DockerImageData } from './dockerImage';
import { USBMetadata } from './usbMetadata';

export const USBDriveData = zod.object({
  mountPath: zod.string(),
  label: zod.string(),
  backups: zod.array(DBBackupData),
  dockerImages: zod.array(DockerImageData),
  metadata: USBMetadata,
});

export type USBDriveData = zod.infer<typeof USBDriveData>;
