import { CNCMachineState } from '../implementations/CNCMachine/types';
import { CustomDeviceStateSchema } from '../implementations/CustomDevice/types/CustomDeviceState';
import { CustomGripperState } from '../implementations/CustomGripper/types';
import { CustomIOState } from '../implementations/CustomIO/types/CustomIOState';
import { DHAG105145State } from '../implementations/dh/instances/DHAG_105_145/State';
import { DHCGI100170State } from '../implementations/dh/instances/DHCGI_100_170/State';
import { DHPGC30060State } from '../implementations/dh/instances/DHPGC_300_60/State';
import { EwellixLiftTLTState } from '../implementations/EwellixLiftTLT/types';
import { InvalidConfigurationState } from '../implementations/InvalidConfiguration/types';
import { ModbusTCPServerState } from '../implementations/ModbusTCPServer/types';
import { NoGripperState } from '../implementations/NoGripper/types';
import { OnRobot2FG14State } from '../implementations/onRobot/OnRobot2FG14/types';
import { OnRobot2FG7State } from '../implementations/onRobot/OnRobot2FG7/types';
import { OnRobot3FG15State } from '../implementations/onRobot/OnRobot3FG15/types';
import { OnRobotDualQuickChangerState } from '../implementations/onRobot/OnRobotDualQuickChanger/types';
import { OnRobotHexEState } from '../implementations/onRobot/OnRobotHexE/types';
import { OnRobotScrewdriverState } from '../implementations/onRobot/OnRobotScrewdriver/types';
import { OnRobotVGP20State } from '../implementations/onRobot/OnRobotVGP20/types';
import { SchunkEGxState } from '../implementations/schunk/SchunkEGx/types/SchunkEGxState';
import { StandardBotsRO1State } from '../implementations/StandardBotsRO1/types/StandardBotsRO1State';
import { EsabWeldMachineState } from '../implementations/WeldMachine/EsabWeldMachine/types/EsabWeldMachineState';
import { WeldMachineState } from '../implementations/WeldMachine/generic/types/WeldMachineState';
import { MillerWeldMachineState } from '../implementations/WeldMachine/MillerWeldMachine/types/MillerWeldMachineState';
import { WristCameraState } from '../implementations/WristCamera/types';

/**
 * Device state is the current state of the device.
 */
export const DeviceStateRegistry = [
  OnRobot2FG7State,
  OnRobot2FG14State,
  OnRobot3FG15State,
  OnRobotScrewdriverState,
  OnRobotDualQuickChangerState,
  OnRobotVGP20State,
  CustomGripperState,
  CustomIOState,
  DHAG105145State,
  DHPGC30060State,
  DHCGI100170State,
  SchunkEGxState,
  NoGripperState,
  EwellixLiftTLTState,
  ModbusTCPServerState,
  WristCameraState,
  CNCMachineState,
  StandardBotsRO1State,
  WeldMachineState,
  MillerWeldMachineState,
  EsabWeldMachineState,
  CustomDeviceStateSchema,
  InvalidConfigurationState,
  OnRobotHexEState,
] as const;
