import type { Placement } from '@popperjs/core';
import type React from 'react';
import { createContext, useContext } from 'react';

// Type for popper configuration
export interface PopperConfig {
  content: React.ReactNode;
  referenceElement: HTMLElement | null;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  offset?: number;
  skidding?: number;
  isDark?: boolean;
  preventClickOutsidePropagation?: boolean;
  onClose: () => void;
}

// Context for the shared popper system
export interface SharedPopperContextType {
  showPopper: (config: PopperConfig) => string;
  hidePopper: () => void;
  currentPopperId: string | null;
}

export const SharedPopperContext =
  createContext<SharedPopperContextType | null>(null);

// Hook to use the shared popper system
export function useSharedPopper() {
  const context = useContext(SharedPopperContext);

  if (!context) {
    throw new Error(
      'useSharedPopper must be used within a SharedPopperProvider',
    );
  }

  return context;
}
