import { Button, Icon } from '@sb/design-system';

export interface FallbackProps {
  resetErrorBoundary: () => void;
  ErrorContainer: React.ComponentType<React.PropsWithChildren>;
}

export const FallbackRender = ({
  resetErrorBoundary,
  ErrorContainer,
}: FallbackProps & {
  ErrorContainer: React.ComponentType<React.PropsWithChildren>;
}) => {
  return (
    <ErrorContainer>
      <div className="tw-p-16 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
        <Icon kind="xmarkCircle" className="tw-text-red tw-icon-42 tw-mb-12" />
        <p>Error rendering component</p>
        <Button variant="Flat" onClick={resetErrorBoundary}>
          Tap to reload
        </Button>
      </div>
    </ErrorContainer>
  );
};
