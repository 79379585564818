import * as zod from 'zod';

export const SYSTEM_MONITOR_SHUTDOWN_STATES = [
  'none',
  'pendingShutdown',
  'shutdown',
] as const;

export const SystemMonitorShutdownState = zod.enum(
  SYSTEM_MONITOR_SHUTDOWN_STATES,
);

export type SystemMonitorShutdownState = zod.infer<
  typeof SystemMonitorShutdownState
>;
