import * as zod from 'zod';

const SPEED_LIMIT_OPTIONS = [
  'PARENT_DEFAULTS',
  'NO_MOTION_LIMITS',
  'LIMIT_TO_PERCENTAGE',
  'FULLY_CUSTOMIZE',
] as const;

export const SpeedLimitOption = zod.enum(SPEED_LIMIT_OPTIONS);
export type SpeedLimitOption = zod.infer<typeof SpeedLimitOption>;
