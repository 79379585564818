import type { ReactNode } from 'react';
import { Fragment } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { FallbackRender } from './FallbackRender';

interface ErrorBoundaryProps {
  ErrorContainer?: React.ComponentType<React.PropsWithChildren>;
  children: ReactNode;
  onError?: (error: Error, info: { componentStack: string }) => void;
  onReset?: (details: { reason: string; error?: Error }) => void;
  resetKeys?: any[];
}

export function ErrorBoundary({
  ErrorContainer = Fragment,
  children,
  ...rest
}: ErrorBoundaryProps) {
  const fallbackRender: (props: FallbackProps) => ReactNode = (props) => {
    return <FallbackRender {...props} ErrorContainer={ErrorContainer} />;
  };

  return (
    <ReactErrorBoundary {...rest} fallbackRender={fallbackRender}>
      {children}
    </ReactErrorBoundary>
  );
}
