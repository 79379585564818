import * as zod from 'zod';

// With pulsing pattern, the brightness of the ring cycles from .brightness
// to 0 and back, at the rate of cyclesPerMinute, starting with full brightness
export const LEDColorPulsingPattern = zod.object({
  kind: zod.literal('pulsing'),
  red: zod.number().min(0).max(255),
  green: zod.number().min(0).max(255),
  blue: zod.number().min(0).max(255),
  brightness: zod.number(),
  cyclesPerMinute: zod.number(), // needs to be zero for solid colors
});

// This is a pattern that spins around
export const LEDColorRingPattern = zod.object({
  kind: zod.literal('ring'),
  red: zod.number().min(0).max(255),
  green: zod.number().min(0).max(255),
  blue: zod.number().min(0).max(255),
  brightness: zod.number(),
  length: zod.number().min(0).max(47),
  cyclesPerMinute: zod.number(),
});

export type LEDColorRingPattern = zod.infer<typeof LEDColorRingPattern>;

export const LEDColorPattern = zod.union([
  LEDColorPulsingPattern,
  LEDColorRingPattern,
]);

export type LEDColorPattern = zod.infer<typeof LEDColorPattern>;
