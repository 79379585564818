import * as zod from 'zod';

export const AssemblyItemType = zod.enum(['system', 'user']);
export type AssemblyItemType = zod.infer<typeof AssemblyItemType>;

export const AssemblyDataType = zod.enum(['int', 'real']);
export type AssemblyDataType = zod.infer<typeof AssemblyDataType>;

export const AssemblyItem = zod.object({
  id: zod.string(),
  name: zod.string(),
  variableID: zod.string().optional(),
  type: AssemblyItemType,
  offset: zod.number(),
  length: zod.number(),
  dataType: AssemblyDataType,
  dataTypeLength: zod.number().optional(),
  description: zod.string().optional(),
});

export type AssemblyItem = zod.infer<typeof AssemblyItem>;

export const EtherNetIPConfig = zod.object({
  id: zod.string(),
  host: zod.string(),
  port: zod.number(),
  uponConnectionLoss: zod.enum(['pause', 'stop', 'ignore']),
  inputAssembly: zod.array(AssemblyItem),
  outputAssembly: zod.array(AssemblyItem),
});

export type EtherNetIPConfig = zod.infer<typeof EtherNetIPConfig>;

export const NullableEtherNetIPConfig = EtherNetIPConfig.nullable();

export type NullableEtherNetIPConfig = zod.infer<
  typeof NullableEtherNetIPConfig
>;
