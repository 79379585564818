import * as zod from 'zod';

import { OnRobot2FG7Command } from '../../OnRobot2FG7/types';
import { OnRobot3FG15Command } from '../../OnRobot3FG15/types';

export const OnRobotQuickChangerCompatibleCommand = zod.union([
  OnRobot2FG7Command,
  OnRobot3FG15Command,
]);

export type OnRobotQuickChangerCompatibleCommand = zod.infer<
  typeof OnRobotQuickChangerCompatibleCommand
>;
