// @ts-ignore
import type * as RCL from 'rclnodejs';

import { info, warn, error, namespace } from '@sb/log';
import { ActionCancelledError } from '@sb/ros/errors/ActionCancelledError';
import { ActionTimeoutError } from '@sb/ros/errors/ActionTimeoutError';
import { GoalNotSucceededError } from '@sb/ros/errors/GoalNotSucceedError';
import { NoResponseError } from '@sb/ros/errors/NoResponseError';
import { getNode } from '@sb/ros/getNode';
import { getRCL } from '@sb/ros/getRCL';

const ns = namespace('BaseAction');

/**
 * Base action class for sending goals to ROS2 action servers.
 *
 * To use, extend this class, then override the following methods:
 * - `getType()`: Return the type of the action as a string.
 * - `getTopic()`: Return the topic of the action as a string.
 * - `createGoal()`: Return the goal message to send to the action server.
 */
export abstract class BaseAction<
  ActionType extends RCL.TypeClass<RCL.ActionTypeClassName>,
  GoalType extends RCL.ActionGoal<ActionType>,
  ReturnType extends RCL.ActionResult<ActionType>,
> {
  public static WAIT_FOR_SERVER_TIMEOUT = 3;

  // cache of the action client for each class
  private static clientMap: Map<string, any> = new Map<string, any>();

  // remove the action client from the cache, used mainly for testing
  public static deleteClient(topicName: string) {
    BaseAction.clientMap.delete(topicName);
  }

  protected goalHandle: RCL.ClientGoalHandle<ActionType> | undefined | void;

  private cancelled = false;

  private lastFeedbackDate: Date | undefined = undefined;

  private startListeningDate: Date = new Date();

  public async call(): Promise<ReturnType> {
    info(ns`sendGoal`, `creating goal`);
    const goalMessage = await this.createGoal();

    info(ns`sendGoal`, `sending goal request (${this.getTopic()})`);
    const client = await this.getClient();

    this.goalHandle = await this.waitWithFeedbackTimeout(
      client.sendGoal(goalMessage, (feedback: any) => {
        this.onFeedback(feedback);
      }),
    );

    if (!this.goalHandle) {
      throw new Error('Goal timed out');
    }

    if (this.cancelled) {
      // Goal was cancelled while waiting for the goal handle
      warn(ns`sendGoal`, 'goal cancelled');

      throw new ActionCancelledError('Goal cancelled');
    }

    if (!this.goalHandle.isAccepted()) {
      info(ns`sendGoal`, 'goal rejected');

      throw new Error('Goal rejected');
    }

    info(ns`sendGoal`, 'goal accepted');

    const result = await this.waitWithFeedbackTimeout(
      this.goalHandle.getResult(),
    );

    if (!this.goalHandle.isSucceeded()) {
      if (this.cancelled) {
        warn(ns`sendGoal`, 'goal cancelled');

        throw new ActionCancelledError('Goal cancelled');
      } else {
        error(ns`sendGoal`, 'goal not succeeded');

        throw new GoalNotSucceededError('Goal not succeeded');
      }
    }

    if (!result) {
      throw new Error('Result has timed out');
    }

    info(ns`sendGoal`, 'goal succeeded');

    return result;
  }

  public async cancel() {
    this.cancelled = true;

    if (!this.goalHandle) {
      info(ns`cancel`, 'no goal to cancel');

      return;
    }

    info(ns`cancel`, `cancelling ${this.getTopic()} goal`);
    await this.goalHandle.cancelGoal();
    info(ns`cancel`, `cancelled ${this.getTopic()} goal`);
  }

  private waitWithFeedbackTimeout(promise: Promise<any>): Promise<any> {
    this.startListeningDate = new Date();

    let interval: NodeJS.Timeout;

    const getTimeDiffSinceLastFeedback = () => {
      if (!this.lastFeedbackDate) {
        return new Date().getTime() - this.startListeningDate.getTime();
      }

      return new Date().getTime() - this.lastFeedbackDate.getTime();
    };

    const feedbackCheckPromise = new Promise<void>((_, reject) => {
      interval = setInterval(() => {
        const diff = getTimeDiffSinceLastFeedback();

        if (diff > this.getTimeout()) {
          clearInterval(interval);

          if (this.lastFeedbackDate !== undefined) {
            reject(
              new ActionTimeoutError(
                'Feedback was not received in the last second.',
              ),
            );
          }

          reject(new NoResponseError('Feedback was not received.'));
        }
      }, 100); // Check every 100ms
    });

    return Promise.race([promise, feedbackCheckPromise]).finally(() => {
      info(ns`createPromiseWithInterval`, 'clearing interval');

      if (interval) {
        clearInterval(interval);
      }
    });
  }

  protected createGoal(): Promise<GoalType> {
    throw new Error('Method not implemented.');
  }

  protected async getClient(): Promise<RCL.ActionClient<ActionType>> {
    let client = BaseAction.clientMap.get(this.getTopic());

    if (!client) {
      const rcl = await getRCL();

      client = new rcl.ActionClient(
        await getNode(),
        this.getType(),
        this.getTopic(),
      );

      BaseAction.clientMap.set(this.getTopic(), client);

      info(ns`sendGoal`, 'waiting for action server...');

      await client.waitForServer(BaseAction.WAIT_FOR_SERVER_TIMEOUT);
    }

    return client;
  }

  /**
   * Get the ros2 action type as a string
   * example: 'standard_bots_msgs/action/Execute'
   */
  protected abstract getType(): RCL.TypeClass<keyof RCL.ActionsMap>;

  /**
   * Get the ros2 action topic
   * example: '/execute'
   */
  protected abstract getTopic(): string;

  /**
   * Timeout in ms for the feedback check
   */
  protected getTimeout(): number {
    return 3000;
  }

  protected onFeedback(_: any) {
    this.lastFeedbackDate = new Date();
  }
}
