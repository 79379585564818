import fs from 'node:fs/promises';
import path from 'node:path';

import type { ZodType } from 'zod';

import { namespace, info, warn } from '@sb/log';

import { mutex } from './mutex';
import { ActiveBuildData, TargetBuildData } from './types';

const CODE_BLOCKS_BASE_PATH = '/etc/standardbots/code_blocks';
const CONFIG_BASE_PATH = '/etc/standardbots/configuration';
const ACTIVE_BUILD_FILE_PATH = `${CONFIG_BASE_PATH}/active_build.json`;
const TARGET_BUILD_FILE_PATH = `${CONFIG_BASE_PATH}/target_build.json`;

const ns = namespace('buildconfig');

export const warningLogged = new Set<string>();

const readZodObjectFromFile = async <T extends ZodType<any>>(
  pathToFile: string,
  schema: T,
): Promise<T['_type'] | undefined> => {
  try {
    const jsonData = JSON.parse(await fs.readFile(pathToFile, 'utf-8'));

    const parsedInfo = schema.parse(jsonData);
    warningLogged.delete(pathToFile);

    return parsedInfo;
  } catch (e) {
    if (!warningLogged.has(pathToFile)) {
      warn(
        ns`parse.error`,
        `Could not read data from ${pathToFile}: ${e.message}`,
      );

      warningLogged.add(pathToFile);
    }

    return undefined;
  }
};

export async function getActiveBuildFromHost(): Promise<ActiveBuildData> {
  // TODO: do we really want to 'active' if the file doesn't exist or is malformed?
  return (
    (await readZodObjectFromFile(ACTIVE_BUILD_FILE_PATH, ActiveBuildData)) || {
      buildId: 'active',
      name: 'Unavailable',
    }
  );
}

export async function getTargetBuildFromHost(): Promise<
  TargetBuildData | undefined
> {
  return readZodObjectFromFile(TARGET_BUILD_FILE_PATH, TargetBuildData);
}

export const setActiveBuildOnHost = async (data: ActiveBuildData) => {
  info(
    ns`active.update.stateChange`,
    `Updating active build to: ${data.buildId}`,
  );

  await fs.writeFile(ACTIVE_BUILD_FILE_PATH, JSON.stringify(data));
};

// update is in a mutex so we don't try to read at the same time as a write,
// which causes the read to fail
export const updateTargetBuildOnHost = mutex(
  async (
    data: Partial<TargetBuildData>,
    {
      clearExisting,
    }: {
      clearExisting?: 'all' | 'error';
    } = {},
  ) => {
    info(
      ns`target.update`,
      `Updating target build data with ${JSON.stringify(
        data,
      )} (clearExisting: ${clearExisting})`,
    );

    try {
      const offlineUpdateDefaultValues = {
        imagesToPull: [],
        strategy: 'EMERGENCY_IMMEDIATE_SKIP_STOPPING',
      };

      const existingData = await getTargetBuildFromHost();

      let existingDataToUse;

      switch (clearExisting) {
        case 'all':
          // Don't use any existing data
          existingDataToUse = {};
          break;
        case 'error':
          // Use the existing data except the error fields
          existingDataToUse = {
            ...existingData,
            lastErrorAt: undefined,
            lastErrorMessage: undefined,
          };

          break;
        default:
          // Use all the existing data
          existingDataToUse = { ...existingData };
      }

      // By using TargetBuildData.parse, we ensure that the data is valid
      const dataToWrite = TargetBuildData.parse({
        ...(data?.offlineUpdatePath && offlineUpdateDefaultValues),
        ...existingDataToUse,
        ...data,
        updatedAt: new Date(),
      });

      info(ns`target.update`, `dataToWrite`, { dataToWrite });

      await fs.writeFile(TARGET_BUILD_FILE_PATH, JSON.stringify(dataToWrite));

      const isStatusChanged = dataToWrite.status !== existingData?.status;

      if (isStatusChanged) {
        info(
          ns`target.update`,
          `Target build status successfully updated to ${dataToWrite.status.toUpperCase()}`,
          { currentStatus: dataToWrite.status },
        );
      }
    } catch (err) {
      info(ns`target.update`, `error`, { error: err });
    }
  },
);

export const saveCodeBlocksOnDisk = async (
  codeBlockId: string,
  codeBlockCode: string,
) => {
  await fs.writeFile(
    path.join(CODE_BLOCKS_BASE_PATH, `${codeBlockId}.py`),
    codeBlockCode,
  );
};

const fileExists = async (filepath: string): Promise<boolean> => {
  try {
    await fs.access(filepath);

    return true;
  } catch (e) {
    return false;
  }
};

export const readCodeBlocksOutputOnDisk = async (
  codeBlockId: string,
  kind: 'stdout' | 'stderr',
) => {
  const codeBlocksOutputPath = path.join(
    CODE_BLOCKS_BASE_PATH,
    `${codeBlockId}.${kind}.out`,
  );

  if (!(await fileExists(codeBlocksOutputPath))) {
    return '';
  }

  return await fs.readFile(codeBlocksOutputPath, { encoding: 'utf-8' });
};
