import { makeNamespacedLog } from '@sb/log';
import type { ArmTarget, TCPOffsetOption } from '@sb/motion-planning';
import type {
  EquipmentStateItem,
  RoutineContext,
  StepFailure,
} from '@sb/routine-runner';
import { FailureKind } from '@sb/routine-runner';
import type ArcStep from '@sb/routine-runner/Step/Arc/Step';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';
import type WaypointStep from '@sb/routine-runner/Step/Waypoint/Step';
import { wait } from '@sb/utilities';
import type { NonEmptyArray } from '@sb/utilities/src/types';

import { WeldArguments } from './Arguments';
import { WeldVariables } from './Variables';

const log = makeNamespacedLog('WeldStep');

export class WeldStep extends Step<WeldArguments, WeldVariables> {
  public static areSubstepsRequired = true;

  public static Arguments = WeldArguments;

  public static Variables = WeldVariables;

  public substeps: Array<Step<object, object>> = [];

  protected initializeVariableState(): void {
    const { completedWeldCount = 0 } = this.variablesForInitialization;

    this.variables = {
      completedWeldCount,
      currentActivity: 'none',
      approachMovementActivity: 'none',
      weldMovementActivity: 'none',
    };
  }

  private motionApproach?: ReturnType<RoutineContext['doMotion']>;

  private motionWeld?: ReturnType<RoutineContext['doMotion']>;

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    log.info('_play', 'Playing Weld step');

    const { weldMachine, torch } = this.activeEquipment;

    if (weldMachine == null) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld machine not found',
      });

      return;
    }

    if (torch == null) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld torch not found',
      });

      return;
    }

    this.setRoutineContextTCPOffsetOption();

    this.setVariable('currentActivity', 'approaching');
    const approachCompleted = await this.moveToApproachPosition({ fail });

    if (!approachCompleted) {
      log.warn('_play', 'Approach movement failed. Returning.', {
        variables: this.variables,
      });

      return;
    }

    this.setVariable('currentActivity', 'settingWeldParameters');
    await this.setWeldMachineParameters({ weldMachine, fail });

    this.setVariable('currentActivity', 'startArc');
    await this.startWeldMachine({ weldMachine, fail });

    await wait(this.args.arcStartTime);

    this.setVariable('currentActivity', 'movingDuringWeld');

    const weldCompleted = await this.moveDuringWeld({
      substeps: this.substeps.slice(1) as (WaypointStep | ArcStep)[],
      fail,
    });

    // If weld doesn't complete, stop arc right away. No crater filling.
    if (weldCompleted) {
      this.setVariable('currentActivity', 'craterFilling');

      await wait(this.args.craterFillTime);
    }

    this.setVariable('currentActivity', 'stopArc');
    await this.stopWeldMachine({ weldMachine });

    this.setVariable('currentActivity', 'none');

    this.setVariable(
      'completedWeldCount',
      this.variables.completedWeldCount + 1,
    );
  }

  public async _stop(): Promise<void> {
    log.info('_stop', 'Stopping Weld step');

    const { weldMachine } = this.activeEquipment;

    if (weldMachine == null) {
      log.info('_stop', 'No weld machine found. Nothing to stop.');

      return;
    }

    this.setVariable('currentActivity', 'stopArc');
    await this.stopWeldMachine({ weldMachine });

    if (this.motionApproach != null) {
      this.motionApproach.emit('cancel');
    }

    if (this.motionWeld != null) {
      this.motionWeld.emit('cancel');
    }

    this.setVariable('currentActivity', 'none');
  }

  public async _pause(): Promise<void> {
    log.info('_pause', 'Pausing Weld step');

    const { weldMachine } = this.activeEquipment;

    if (weldMachine == null) {
      log.info('_pause', 'No weld machine found. Nothing to pause.');

      return;
    }

    await this.stopWeldMachine({ weldMachine });

    if (this.motionApproach != null) {
      this.motionApproach.emit('pause');
    }

    if (this.motionWeld != null) {
      this.motionWeld.emit('pause');
    }
  }

  public async _resume(): Promise<void> {
    log.info('_resume', 'Resuming Weld step');

    const { weldMachine } = this.activeEquipment;

    if (weldMachine == null) {
      log.info('_resume', 'No weld machine found. Nothing to resume.');

      return;
    }

    // Main question: do we need to be starting weld machine again once approach is complete?
    if (this.motionApproach != null) {
      this.motionApproach.emit('resume');
      this.setVariable('approachMovementActivity', 'moving');
    }

    if (this.motionWeld != null) {
      this.setVariable('currentActivity', 'startArc');

      await this.startWeldMachine({ weldMachine });

      this.motionWeld.emit('resume');
      this.setVariable('weldMovementActivity', 'moving');
      this.setVariable('currentActivity', 'movingDuringWeld');
    }
  }

  private get activeEquipment(): {
    weldMachine: EquipmentStateItem | undefined;
    torch: EquipmentStateItem | undefined;
  } {
    const allEquipment = this.routineContext.equipment.getEquipmentState();

    const weldMachine = allEquipment.find(
      (equipment) => equipment.id === this.args.selectedMachineID,
    );

    const torch = allEquipment.find(
      (equipment) => equipment.id === this.args.selectedTorchID,
    );

    return { weldMachine, torch };
  }

  private getTCPOffsetOption(): TCPOffsetOption {
    const { torch } = this.activeEquipment;

    if (torch == null) {
      throw new Error('Torch not found');
    }

    if (this.args.selectedTorchID == null) {
      throw new Error('Selected torch ID is null');
    }

    // TCP Offset option a bit of a hack. May need to revisit the indexing once we add dedicated torches, not just custom grippers.
    // See https://github.com/standardbots/sb/blob/540d718e5d2bbe3226ea9b4128b9f49392bfc6ef/libs/integrations/implementations/CustomGripper/frontend.tsx#L30
    return `ee-${torch.state.kind}-0`;
  }

  private setRoutineContextTCPOffsetOption(): void {
    const tcpOffset = this.getTCPOffsetOption();
    this.routineContext.setTCPOffsetOption(tcpOffset);
  }

  private async moveToApproachPosition({
    fail,
  }: {
    fail: (failure: StepFailure) => void;
  }): Promise<boolean> {
    log.info('moveToApproachPosition', 'Moving to approach position');

    let completed = false;

    if (this.substeps.length === 0) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'No waypoints found',
      });

      return completed;
    }

    const approachWaypoint = this.substeps[0] as WaypointStep | ArcStep;

    if (
      approachWaypoint.getStepKind() !== 'Waypoint' &&
      approachWaypoint.getStepKind() !== 'Arc'
    ) {
      // This is a dev error.
      throw new Error(
        `Approach position is not a waypoint or arc: kind=${approachWaypoint.getStepKind()}`,
      );
    }

    const target = await approachWaypoint.getArmTarget({
      effectiveTCPOption: this.getTCPOffsetOption(),
      parentCompletedCount: this.variables.completedWeldCount,
    });

    if ('failure' in target) {
      fail(target);

      return completed;
    }

    // Weld does not observe push mode: should not be pushing up against object.
    // Also, ros2 does not support push at this time (2025-02-19).

    const motion = this.routineContext.doMotion({
      request: {
        targets: [target] as NonEmptyArray<ArmTarget>,
      },
      speedProfiles: [this.args.approachSpeedProfile],
      pushUntilCollision: false,
      pushMode: false,
      stepID: this.id,
    });

    motion.on('requestingPlan', () => {
      // Only checking previous state for first few states because we could be in a play/pause/resume flow.
      if (this.variables.approachMovementActivity === 'none') {
        this.setVariable('approachMovementActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.approachMovementActivity === 'requestingPlan') {
        this.setVariable('approachMovementActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('approachMovementActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('approachMovementActivity', 'paused');
    });

    motion.on('complete', () => {
      log.debug('moveToApproachPosition', 'Approach motion complete');

      completed = true;
    });

    motion.on('cancelled', () => {
      this.setVariable('approachMovementActivity', 'none');
    });

    motion.on('failure', (failure) => {
      this.setVariable('approachMovementActivity', 'none');
      fail(failure);
    });

    this.motionApproach = motion;

    try {
      await motion.race('failure', 'complete', 'cancelled');
    } finally {
      this.setVariable('approachMovementActivity', 'none');
      motion.removeAllListeners();

      delete this.motionApproach;
    }

    return completed;
  }

  /**
   * Handles movement one set of waypoints at a time.
   *
   * Make this recursive because it makes it easy for the movement to pick up where it left off on pause/resume.
   */
  private async moveDuringWeld({
    substeps,
    fail,
  }: {
    substeps: (WaypointStep | ArcStep)[];
    fail: (failure: StepFailure) => void;
  }): Promise<boolean> {
    let completed = false;

    log.info('moveDuringWeld', 'Moving during weld iteration');

    let targets: NonEmptyArray<ArmTarget> | null = null;

    for (let i = 0; i < substeps.length; i += 1) {
      const substep = substeps[i];

      if (
        substep.getStepKind() !== 'Waypoint' &&
        substep.getStepKind() !== 'Arc'
      ) {
        // This is a dev error. Will need to update once we add additional flourishes.
        throw new Error(
          `Substep is not a waypoint or arc: kind=${substep.getStepKind()}`,
        );
      }

      const target = await substep.getArmTarget({
        effectiveTCPOption: this.getTCPOffsetOption(),
        parentCompletedCount: this.variables.completedWeldCount,
      });

      if ('failure' in target) {
        fail(target);

        return completed;
      }

      if (targets == null) {
        targets = [target];
      } else {
        targets.push(target);
      }
    }

    if (targets == null || targets.length === 0) {
      log.debug('moveDuringWeld', 'No targets found. Returning.');

      return completed;
    }

    // Weld does not observe push mode: should not be pushing up against object.
    // Also, ros2 does not support push at this time (2025-02-19).
    const motion = this.routineContext.doMotion({
      request: {
        targets,
      },
      speedProfiles: targets.map(() => this.args.weldTravelSpeedProfile),
      pushUntilCollision: false,
      pushMode: false,
      stepID: this.id,
    });

    motion.on('requestingPlan', () => {
      if (this.variables.weldMovementActivity === 'none') {
        this.setVariable('weldMovementActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.weldMovementActivity === 'requestingPlan') {
        this.setVariable('weldMovementActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('weldMovementActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('weldMovementActivity', 'paused');
    });

    motion.on('complete', () => {
      log.debug('moveDuringWeld', 'Welding motion complete');

      completed = true;
    });

    motion.on('cancelled', () => {
      this.setVariable('weldMovementActivity', 'none');
    });

    motion.on('failure', (failure) => {
      this.setVariable('weldMovementActivity', 'none');
      fail(failure);
    });

    this.motionWeld = motion;

    try {
      await motion.race('failure', 'complete', 'cancelled');
    } finally {
      this.setVariable('weldMovementActivity', 'none');
      motion.removeAllListeners();

      delete this.motionWeld;
    }

    return completed;
  }

  /**
   * Stop the current weld machine.
   *
   * Weld stop commands may be sent even if welding hasn't started yet.
   */
  private async stopWeldMachine({
    weldMachine,
  }: {
    weldMachine: EquipmentStateItem;
  }): Promise<void> {
    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.stopMillerWeldMachine();

        break;
      case 'EsabWeldMachine':
        log.error('stopWeldMachine', 'ESAB weld machine not supported');

        break;
      case 'WeldMachine':
        log.error('stopWeldMachine', 'Generic weld machine not supported');

        break;
      default:
        log.error(
          'stopWeldMachine',
          `Unknown weld machine type: ${weldMachine.kind}`,
        );

        break;
    }
  }

  private async startWeldMachine({
    weldMachine,
    fail,
  }: {
    weldMachine: EquipmentStateItem;
    fail?: (failure: StepFailure) => void;
  }): Promise<void> {
    let failureReason: string;

    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.startMillerWeldMachine();
        break;
      case 'EsabWeldMachine':
        failureReason = 'ESAB weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      case 'WeldMachine':
        failureReason = 'Generic weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      default:
        failureReason = `Unknown weld machine type: ${weldMachine.kind}`;

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
    }
  }

  private async setWeldMachineParameters({
    weldMachine,
    fail,
  }: {
    weldMachine: EquipmentStateItem;
    fail?: (failure: StepFailure) => void;
  }): Promise<void> {
    let failureReason: string;

    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.setMillerWeldParameters();
        break;
      case 'EsabWeldMachine':
        failureReason = 'ESAB weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      case 'WeldMachine':
        failureReason = 'Generic weld machine not supported';

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
      default:
        failureReason = `Unknown weld machine type: ${weldMachine.kind}`;

        if (fail != null) {
          fail({
            failure: {
              kind: FailureKind.WeldFailure,
            },
            failureReason,
          });
        } else {
          log.error('startWeldMachine', failureReason);
        }

        break;
    }
  }

  private async stopMillerWeldMachine(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'stopArc',
      },
    });
  }

  private async startMillerWeldMachine(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'startArc',
      },
    });
  }

  private async setMillerWeldParameters(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'setWeldParameters',
        weldTravelSpeed: 0, // This is disregarded by machine. Just set it like this for now.
        ...this.args.millerWeldParameters,
      },
    });
  }
}
