import * as zod from 'zod';

import { RoutineStepConfiguration } from '@sb/routine-runner';

export const RoutineRunnerStateKind = zod.enum([
  'Idle',
  'RunningAdHocCommand',
  'RoutineRunning',
  'Antigravity',
  'AntigravitySlow',
  'Failure',
  'Recovering',
  'Constructing',
]);

export const RoutineRunnerStateData = zod.object({
  id: zod.string(),
  configuration: zod.any().optional(),
  currentStepID: zod.string().optional(),
  failureReason: zod.string().optional(),
  failedStep: zod.string().optional(),
  failure: zod.any().optional(),
  isPaused: zod.boolean(),
  kind: RoutineRunnerStateKind,
  loadedSteps: zod.array(RoutineStepConfiguration).optional(),
  routineID: zod.string().optional(),
  robotID: zod.string(),
  failureTraceID: zod.string().optional(),
  variables: zod.record(zod.record(zod.any())).optional(),
  isEStopped: zod.boolean().optional(),
  forceRecoveryMode: zod.boolean().optional(),
});

export type RoutineRunnerStateData = zod.infer<typeof RoutineRunnerStateData>;
