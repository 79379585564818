import type { StepKind } from '@sb/routine-runner';

export const ALL_WELD_MACHINE_INTEGRATION_TYPES = [
  'WeldMachine',
  'MillerWeldMachine',
  'EsabWeldMachine',
];

// TODO: Update once we add other step kinds, etc.
export const TARGET_POINTS_STEP_KIND: StepKind[] = ['Waypoint', 'Arc'];

// Torches documentation: https://www.notion.so/standardbots/PRD-Welding-f5be80c80d3942319c74f0361241aff4?pvs=4#112d5837614380dfa985e238e966f2fd
export const torchConfigs = {
  // 22 degree torch configuration
  ba1_22deg: {
    name: 'BA1 22° Torch',
    model: '405-22QC',
    tcpTransform: {
      // Position coordinates
      x: 0,
      y: 0.015, // 15mm offset
      z: 0.35, // 350mm TCP length
      // Orientation coordinates
      w: Math.cos((22 * Math.PI) / 360),
      i: 0,
      j: Math.sin((22 * Math.PI) / 360),
      k: 0,
    },
  },
  // 45 degree torch configuration
  ba1_45deg: {
    name: 'BA1 45° Torch',
    model: '405-45QC',
    tcpTransform: {
      // Position coordinates
      x: 0,
      y: 0.015, // 15mm offset
      z: 0.35, // 350mm TCP length
      // Orientation coordinates
      w: Math.cos((45 * Math.PI) / 360),
      i: 0,
      j: Math.sin((45 * Math.PI) / 360),
      k: 0,
    },
  },
};
