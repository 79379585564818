import * as zod from 'zod';

import { USBMetadata } from './usbMetadata';

export const LogsBackupStatus = zod.enum(['failed', 'creating', 'success']);

export type LogsBackupStatus = zod.infer<typeof LogsBackupStatus>;

export const LogsBackupRequestInput = zod.object({
  path: zod.string().optional(),
  robotID: zod.string(),
  robotSerial: zod.string().optional(),
  backupQuota: zod.number(),
  usbMetadata: USBMetadata,
});

export type LogsBackupRequestInput = zod.infer<typeof LogsBackupRequestInput>;

export const LogsBackupData = zod.object({
  timestamp: zod.string(),
  path: zod.string(),
  robotID: zod.string(),
  robotSerial: zod.string().optional(),
  backupQuota: zod.number().optional(),
  usbMetadata: USBMetadata,
  date: zod.date(),
  status: zod.optional(LogsBackupStatus),
  error: zod.optional(zod.string()),
});

export type LogsBackupData = zod.infer<typeof LogsBackupData>;
