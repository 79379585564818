import type * as zod from 'zod';

import { posesAreEqual } from '@sb/geometry';
import type { TCPOffsetOption } from '@sb/motion-planning';
import { ArmTarget, DEFAULT_TCP_OFFSET_OPTION } from '@sb/motion-planning';
import type { StepFailure } from '@sb/routine-runner';
import { FailureKind } from '@sb/routine-runner';

import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class ArcStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  /**
   * Placeholder method for the play logic.
   * The play method is not used directly for the arc step.
   */
  public async _play(): Promise<void> {
    // NO _play method for ArcStep. Use getArmTarget with moveArmToV2 or Weld.
  }

  public async getArmTarget({
    effectiveTCPOption = undefined,
    parentCompletedCount,
  }: {
    effectiveTCPOption?: TCPOffsetOption;
    parentCompletedCount: number;
  }): Promise<ArmTarget | StepFailure> {
    // TODO Implement getArmTarget for ArcStep. Current implementation is a placeholder that just moves us to the "end" of the arc.
    const argsTarget = this.args.targets[1];

    const parseTarget = (armPosition: any) => {
      const target = {
        motionKind: 'joint',
        ...armPosition,
        calibrationPose: armPosition.pose,
        blendRadius: 0,
        stopHere: this.args.stopHere,
      };

      // remove jointAngle fields
      target.jointAngles = undefined;
      target.calibrationPose = undefined;

      // set effectiveTCPOption
      if (effectiveTCPOption) {
        // weld waypoints use the effectiveTCPOption from the parent weld step
        target.effectiveTCPOption = effectiveTCPOption;
      } else if (this.args.tcpOption === 'auto') {
        if ('tcpOption' in target && target.tcpOption) {
          // use tcpOption that target was originally created with
          // we can leave target.tcpOption as is.
          target.effectiveTCPOption = target.tcpOption;
        } else {
          // use default tcpOption
          target.effectiveTCPOption = DEFAULT_TCP_OFFSET_OPTION;
        }
      } else {
        // use tcpOption that was specified in the Waypoint step
        target.effectiveTCPOption = this.args.tcpOption;
      }

      const parsedTarget = ArmTarget.safeParse(target);

      if (!parsedTarget.success) {
        throw new Error('Move arm target is not valid');
      }

      return parsedTarget.data;
    };

    let target: ArmTarget;
    let anchor: any; // Space Item

    try {
      if ('positionListID' in argsTarget) {
        target = parseTarget(
          this.routineContext.getPositionListEntry(
            argsTarget.positionListID,
            argsTarget.positionListIndex ?? parentCompletedCount,
          ),
        );

        const targetSpace = this.routineContext.getSpaceItem(
          argsTarget.positionListID,
        );

        if (targetSpace.anchoredToID) {
          anchor = this.routineContext.getSpaceItem(targetSpace.anchoredToID);
        }
      } else if ('expression' in argsTarget) {
        const expressionTarget = await this.routineContext.evaluateExpression(
          argsTarget.expression,
        );

        // if expression evaluates to an array, use the first item
        // this makes it easier to use 'SinglePosition' space objects.
        target = parseTarget(
          Array.isArray(expressionTarget)
            ? expressionTarget[0]
            : expressionTarget,
        );
      } else {
        target = parseTarget(argsTarget);
      }

      if (
        'positionListID' in argsTarget &&
        anchor &&
        anchor.kind === 'localAccuracyCalibration'
      ) {
        if ('pose' in target) {
          target.pose = this.routineContext.getCalibratedGoal(
            target.pose,
            anchor,
          );
        } else if ('jointAngles' in target && 'calibrationPose' in target) {
          const calibratedPose = this.routineContext.getCalibratedGoal(
            target.calibrationPose,
            anchor,
          );

          // do not change the original target object if pose did not change
          if (!posesAreEqual(target.calibrationPose, calibratedPose)) {
            const newJointAngles =
              await this.routineContext.getJointAnglesForCartesianSpacePose(
                calibratedPose,
                'joint',
                false,
              );

            if (newJointAngles) {
              target.jointAngles = newJointAngles;
            }
          }
        }
      }

      return target;
    } catch (error) {
      return {
        failure: {
          kind: FailureKind.InvalidRoutineLoadedFailure,
        },
        failureReason: error.message,
        error,
      };
    }
  }
}
