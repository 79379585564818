import * as zod from 'zod';

export const StandardBotsRO1Configuration = zod.object({
  kind: zod.literal('StandardBotsRO1'),
  name: zod.string().default('Secondary Control Box'),
  url: zod.string().default(''),
  token: zod.string().default(''),
  botID: zod.string().default(''),
});

export type StandardBotsRO1Configuration = zod.infer<
  typeof StandardBotsRO1Configuration
>;
