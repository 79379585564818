import '@sb/design-system';
/* remove the next import when everything is moved over to the new design system */
import '@sb/ui/global-tailwind.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { SharedPopperProvider } from '@sb/design-system/utility/shared-popper';
import { makeNamespacedLog } from '@sb/log';
import { wait } from '@sb/utilities';
import AppProvider from '@sbrc/components/AppProvider';
import { ControlPanelProtection } from '@sbrc/components/ControlPanelProtection';
import { ErrorBoundary } from '@sbrc/components/ErrorBoundary';
import { pollSoftwareBuildData } from '@sbrc/hooks';

import ErrorPage from './404';

const log = makeNamespacedLog('app');

/**
 * Attempts to import a module, and if it fails, attempts to reload the page.
 *
 * Targeted at dynamic module import errors.
 *
 * @param importer - The module function used to import.
 * @returns The imported module.
 */
async function safeImport<T>(importer: () => Promise<T>) {
  try {
    return await importer();
  } catch (error) {
    log.warn('safeImport', 'Module import failed. Attempt to reload page.', {
      error,
    });

    const modulePathString = importer.toString();
    const moduleMatch = modulePathString.match(/import\(['"](.+)['"]\)/);

    if (!(moduleMatch != null && moduleMatch[1] != null)) {
      throw new Error(
        `Failed to parse module path from importer (importer=${modulePathString}). Original error: ${error}`,
      );
    }

    const url = new URL(moduleMatch[1], window.location.origin);

    for (let i = 0; i < 5; i += 1) {
      await wait(1000 * i); // Backoff

      if (i > 0) {
        // Try initial import again with cache busting for network issues.
        url.searchParams.set('t', `${+new Date()}`); // cache busting
      }

      const newImportPath = url.pathname + url.search;

      try {
        /* @vite-ignore */
        return await import(newImportPath);
      } catch (e) {
        log.error('safeImport', `safeImport failed (attempt ${i})`, {
          error: e,
        });
      }
    }

    throw error;
  }
}

// converter for react-router:
// - wrap page component in error boundary
// - named export `Component`
function page<T extends { default: React.ComponentType }>(
  importer: () => Promise<T>,
) {
  return async () => {
    const mdl = await safeImport(importer);

    return {
      ...mdl,
      Component() {
        return (
          <ErrorBoundary>
            <SharedPopperProvider>
              <mdl.default />
            </SharedPopperProvider>
          </ErrorBoundary>
        );
      },
    };
  };
}

function controlPanelProtectedPage<T extends { default: React.ComponentType }>(
  importer: () => Promise<T>,
) {
  return async () => {
    const mdl = await safeImport(importer);

    return {
      ...mdl,
      // eslint-disable-next-line react/no-multi-comp
      Component() {
        return (
          <ErrorBoundary>
            <ControlPanelProtection>
              <mdl.default />
            </ControlPanelProtection>
          </ErrorBoundary>
        );
      },
    };
  };
}

const router = createBrowserRouter([
  // root
  {
    path: '/',
    lazy: page(() => import('./main')),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    lazy: page(() => import('./login')),
  },
  {
    path: '/logout',
    lazy: page(() => import('./logout')),
  },
  {
    path: '/privacy',
    lazy: page(() => import('./privacy')),
  },
  {
    path: '/terms',
    lazy: page(() => import('./terms')),
  },

  // control-panel
  {
    path: '/control-panel',
    lazy: controlPanelProtectedPage(() => import('./control-panel')),
  },
  {
    path: '/control-panel/demos',
    lazy: controlPanelProtectedPage(() => import('./control-panel/demos')),
  },
  {
    path: '/control-panel/equipment-inspector',
    lazy: controlPanelProtectedPage(
      () => import('./control-panel/equipment-inspector'),
    ),
  },
  {
    path: '/control-panel/feature-flags',
    lazy: controlPanelProtectedPage(
      () => import('./control-panel/feature-flags'),
    ),
  },
  {
    path: '/control-panel/firmware-inspector',
    lazy: controlPanelProtectedPage(
      () => import('./control-panel/firmware-inspector'),
    ),
  },
  {
    path: '/control-panel/simulator-bot',
    lazy: controlPanelProtectedPage(
      () => import('./control-panel/simulator-bot'),
    ),
  },
  {
    path: '/control-panel/config-editor',
    lazy: controlPanelProtectedPage(
      () => import('./control-panel/config-editor'),
    ),
  },

  // demo
  {
    path: '/demo/ey',
    lazy: page(() => import('./demo/ey')),
  },
  {
    path: '/demo/skills',
    lazy: page(() => import('./demo/skills')),
  },

  // help
  {
    path: '/help',
    lazy: page(() => import('./help')),
  },
  {
    path: '/help/:article',
    lazy: page(() => import('./help/[article]')),
  },

  // robot
  {
    path: '/box',
    lazy: page(() => import('./box')),
  },
  {
    path: '/move',
    lazy: page(() => import('./move')),
  },
  {
    path: '/preflight',
    lazy: page(() => import('./preflight')),
  },
  {
    path: '/scene',
    lazy: page(() => import('./scene')),
  },

  // robots
  {
    path: '/robots',
    lazy: page(() => import('./robots')),
  },

  // routines
  {
    path: '/routines',
    lazy: page(() => import('./routines')),
  },
  {
    path: '/routines/:routineID',
    lazy: page(() => import('./routines/[routineID]')),
  },

  // test platform ui
  {
    path: '/assembly-tests/test-runs/:testRunId',
    lazy: page(() => import('./assembly-tests')),
  },
  {
    path: '/assembly-tests/*',
    lazy: page(() => import('./assembly-tests')),
  },

  // calibrate arm
  {
    path: '/calibrate-arm',
    lazy: page(() => import('./calibrate-arm')),
  },

  //
  {
    path: '/training-data',
    lazy: page(() => import('./training-data')),
  },
  {
    path: '/training-data/:taskID',
    lazy: page(() => import('./training-data/[taskID]')),
  },

  // webxr
  {
    path: '/webxr',
    lazy: page(() => import('./webxr')),
  },
]);

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </StrictMode>,
);

pollSoftwareBuildData();
