import type { Placement } from '@popperjs/core';
import cx from 'classnames';
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import {
  DesignSystemContextProvider,
  usePortalContainer,
} from '../DesignSystemContext';
import { useClickOutside } from '../useClickOutside';

import { type PopperConfig, SharedPopperContext } from './SharedPopperContext';

const FALLBACK_PLACEMENTS: { [placement in Placement]?: Placement[] } = {
  'bottom-end': ['top-end', 'left-start', 'right-start'],
  'bottom-start': ['top-start', 'right-start', 'left-start'],
  bottom: ['top', 'right-start', 'left-start'],
  'top-end': ['bottom-end', 'left-end', 'right-end'],
  'top-start': ['bottom-start', 'right-end', 'left-end'],
  top: ['bottom', 'right-end', 'left-end'],
};

export function SharedPopperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const parentContainerElement = usePortalContainer();

  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const [currentConfig, setCurrentConfig] = useState<PopperConfig | null>(null);
  const [currentPopperId, setCurrentPopperId] = useState<string | null>(null);

  // Generate a unique ID for each popper to track which one is active
  const popperIdCounter = useRef(0);

  // Popper.js hook
  const { styles, attributes, forceUpdate } = usePopper(
    currentConfig?.referenceElement,
    popperElement,
    {
      placement: currentConfig?.placement || 'bottom',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements:
              currentConfig?.fallbackPlacements ||
              FALLBACK_PLACEMENTS[
                (currentConfig?.placement as Placement) || 'bottom'
              ],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [currentConfig?.skidding || 0, currentConfig?.offset || 4],
          },
        },
        { name: 'preventOverflow', options: { padding: 10 } },
        { name: 'eventListeners', enabled: !!currentConfig },
      ],
    },
  );

  // Force update popper when content changes
  useEffect(() => {
    if (currentConfig?.content && forceUpdate) {
      queueMicrotask(() => {
        forceUpdate();
      });
    }
  }, [currentConfig?.content, forceUpdate]);

  // Show a popper with the given configuration
  const showPopper = useCallback((config: PopperConfig) => {
    popperIdCounter.current += 1;
    const popperId = `popper-${popperIdCounter.current}`;
    setCurrentConfig(config);
    setCurrentPopperId(popperId);

    return popperId;
  }, []);

  /**
   * Hide the current popper.
   *
   * Do not delete the popper, though. We want to keep the portal reference around so that we can, e.g., show confirmation modals, etc.
   */
  const hidePopper = useCallback(() => {
    setCurrentPopperId(null);
  }, []);

  // Handle clicks on buttons inside the popper
  const handleClickPopper: React.MouseEventHandler = (event) => {
    let target: EventTarget | null = event.target; // eslint-disable-line prefer-destructuring

    while (target) {
      if (target instanceof HTMLButtonElement) {
        if (!target.disabled) {
          // Let the click event complete before closing the popper
          requestAnimationFrame(() => {
            currentConfig?.onClose();
            hidePopper();
          });
        }

        target = null;
      } else if (target instanceof Element && target.role === 'button') {
        if (target.ariaDisabled !== 'true') {
          // Let the click event complete before closing the popper
          requestAnimationFrame(() => {
            currentConfig?.onClose();
            hidePopper();
          });
        }

        target = null;
      } else if (target instanceof Element) {
        target = target.parentElement;
      } else {
        target = null;
      }
    }
  };

  // Handle clicks outside the popper
  useClickOutside(
    [containerElement, currentConfig?.referenceElement || null],
    currentConfig
      ? (event) => {
          if (
            containerElement &&
            currentConfig.preventClickOutsidePropagation
          ) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            containerElement.setPointerCapture(event.pointerId);
          }

          currentConfig.onClose();
          hidePopper();
        }
      : undefined,
  );

  // Context value memoized to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      showPopper,
      hidePopper,
      currentPopperId,
    }),
    [showPopper, hidePopper, currentPopperId],
  );

  return (
    <SharedPopperContext.Provider value={contextValue}>
      {children}

      {createPortal(
        <div
          ref={setContainerElement}
          className={cx('tw-contents', currentConfig?.isDark && 'tw-dark')}
        >
          <DesignSystemContextProvider portalContainer={containerElement}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              ref={setPopperElement}
              className={cx(
                // override browser's [hidden] rule so content fades out
                '[&[hidden]]:tw-block',
                '[&[hidden]]:tw-invisible',
                '[&[hidden]]:tw-opacity-0',
                'data-[popper-reference-hidden=true]:tw-invisible',
                'data-[popper-reference-hidden=true]:tw-opacity-0',
                'tw-transition-opacity',
                'tw-z-40',
              )}
              onClick={handleClickPopper}
              style={styles.popper}
              {...attributes.popper}
              hidden={currentPopperId == null}
            >
              {currentConfig?.content}
            </div>
          </DesignSystemContextProvider>
        </div>,
        parentContainerElement,
      )}
    </SharedPopperContext.Provider>
  );
}
