/**
 * Miller welding machine modbus
 * @ref https://drive.google.com/file/d/1Crw9W3lW8ztJhKeRwn52TYPZISKVeBwu/view?usp=sharing
 */
import * as zod from 'zod';

import type { WeldMachineMode } from '../types/MillerWeldMachineState';

export const RETRY_INTERVAL_MS = 10000;

export const INITIALIZATION_TIMEOUT_MS = 5000;

// @see section 3.4.3 "In the case of using single register writes, there is a 200 ms delay after the last program parameter is received before the power source will respond to the request."
export const WELD_PROGRAM_LOAD_MS = 200;

// Wait up to this many MS for the machine to respond to a command. Usually we're looking for some modbus feedback.
export const WAIT_FOR_MACHINE_TO_RESPOND_MS = 4000;

// Poll interval for waiting for the machine to respond to a command.
export const WAIT_FOR_MACHINE_TO_RESPOND_POLL_INTERVAL_MS = 10;

// Coils: R+W booleans
export enum Coils {
  // Weld commands. Table 3-1
  WeldDisable = 0,
  ContactorOn = 1,
  GasEnable = 2,
  MotorForward = 3,
  MotorRetract = 4,
  TouchSenseEnable = 5,
}

// Discrete inputs: Read-only boolean
export enum DiscreteInputs {
  // Weld feedback - Table 3-2
  ArcDetected = 0,
  ContactorStatus = 1,
  GasStatus = 2,
  FeedForwardStatus = 3,
  FeedRetractStatus = 4,
  TouchSenseEnabled = 5,
  WireTouched = 6,
  WeldStateStatus = 7,
  SynergicProcess = 8,
  ErrorActive = 9,
  PSConnected = 10,
  Ready = 11,
}

// Input registers: Read-only numbers
export enum InputRegisters {
  // Weld feedback. Table 3-2
  ActualWFS = 0,
  ActualArcVoltage = 1,
  ActualArcCurrent = 2,
  ActualGasFlow = 3,
  RawHeat = 4,
  HeatInput = 5,
  // Weld setting feedback. Table 3-12
  PresetWFS = 6,
  PresetVoltage = 7,
  PresetArcLength = 8,
  PresetArcControl = 9,
  MemorySlot = 10,
  ActiveProcess = 11, // Table 3-4
  ActiveWiretype = 12, // Table 3-5 (Note: "Wiretype" rather than "WireType" is what is in docs.)
  ActiveWireSize = 13, // Table 3-6
  ActiveGasType = 14, // Table 3-7
  ErrorCode = 16,
  // Weld Sequence Settings (again). Table 3-12
  SequencePreflowEnable = 500,
  SequencePreflowTime = 501,
  SequenceRunInMode = 502,
  SequenceRunInWFS = 503,
  SequenceStartMode = 504,
  SequenceStartWFS = 505,
  SequenceStartVoltage = 506,
  SequenceStartArcLength = 507,
  SequenceStartDwellTime = 508,
  SequenceStartRampTime = 509,
  SequenceProfilePulseMode = 510,
  SequenceCraterMode = 511,
  SequenceBurnbackTime = 512,
  SequencePostflowEnable = 513,
  SequencePostflowTime = 514,
  SequenceRetractEnable = 515,
  SequenceRetractDistance = 516,
}

// Holding registers: R+W numbers
export enum HoldingRegisters {
  // Weld commands. Table 3-1
  PresetWFS = 0,
  PresetVoltage = 1,
  PresetArcLength = 2,
  PresetArcControl = 3,
  SelectMemorySlot = 4,
  // Weld Program Selection. Table 3-3
  WeldProcess = 1000, // Table 3-4. CV MIG/Accu-pulse
  WeldWireType = 1001, // Table 3-5
  WeldWireSize = 1002, // Table 3-6
  WeldGasType = 1003, // Table 3-7
  // Weld Sequence Settings. Table 3-8
  SequencePreflowEnable = 500,
  SequencePreflowTime = 501,
  SequenceRunInMode = 502,
  SequenceRunInWFS = 503,
  SequenceStartMode = 504,
  SequenceStartWFS = 505,
  SequenceStartVoltage = 506,
  SequenceStartArcLength = 507,
  SequenceStartDwellTime = 508,
  SequenceStartRampTime = 509,
  SequenceProfilePulseMode = 510,
  SequenceCraterMode = 511,
  SequenceBurnbackTime = 512,
  SequencePostflowEnable = 513,
  SequencePostflowTime = 514,
  SequenceRetractEnable = 515,
  SequenceRetractDistance = 516,
  // Robot feedback. Table 3-13
  WeldTravelSpeed = 1500, // mm/sec. Setting 12 ~> 1.2mm/sec (notice typo)
}

// Weld Process Enum
export enum WeldProcess {
  CV_MIG = 0,
  Accu_Pulse = 1,
  UNSET = 65535,
}

export const WeldMachineModeToEnum: Required<{
  [K in WeldMachineMode]: WeldProcess;
}> = {
  constantVoltage: WeldProcess.CV_MIG,
  pulse: WeldProcess.Accu_Pulse,
  unset: WeldProcess.UNSET,
};

export const WeldMachineModeFromEnum: Required<{
  [K in WeldProcess]: WeldMachineMode;
}> = {
  [WeldProcess.CV_MIG]: 'constantVoltage',
  [WeldProcess.Accu_Pulse]: 'pulse',
  [WeldProcess.UNSET]: 'unset',
};

// Wire Type Enum
export enum WireTypeRaw {
  Steel_ER70X = 0,
  Metal_Core_EXXC = 1,
  Flux_Core_EXXT = 2,
  Aluminum_4XXX = 3,
  Aluminum_4943 = 4,
  Aluminum_5XXX = 5,
  Stainless_Steel_ER3XX = 6,
  Stainless_Steel_ER4XX = 7,
  UNSET = 65535,
}

export const WireType = zod.nativeEnum(WireTypeRaw);
export type WireType = zod.infer<typeof WireType>;

// Wire Size Enum
export enum WireSizeRaw {
  Size_0_030 = 0,
  Size_0_035 = 1,
  Size_0_040 = 2,
  Size_0_045 = 3,
  Size_3_64 = 4,
  Size_0_052 = 5,
  Size_0_062 = 6,
  Size_0_078 = 7,
  Size_0_093 = 8,
  UNSET = 65535,
}

export const WireSize = zod.nativeEnum(WireSizeRaw);
export type WireSize = zod.infer<typeof WireSize>;

// Gas Type Enum
export enum GasTypeRaw {
  Argon_100 = 0,
  Argon_98_CO2_2 = 1,
  Argon_95_CO2_5 = 2,
  Argon_90_CO2_10 = 3,
  Argon_85_CO2_15 = 4,
  Argon_80_CO2_20 = 5,
  Argon_75_CO2_25 = 6,
  CO2_100 = 7,
  Argon_98_O2_2 = 8,
  Argon_95_O2_5 = 9,
  Helium_100 = 10,
  Helium_75_Argon_25 = 11,
  Helium_50_Argon_50 = 12,
  Helium_25_Argon_75 = 13,
  Argon_81_Helium_18_CO2_1 = 14,
  Argon_66_Helium_33_CO2_1 = 15,
  Helium_90_Argon_7_5_CO2_2_5 = 16,
  UNSET = 65535,
}

export const GasType = zod.nativeEnum(GasTypeRaw);
export type GasType = zod.infer<typeof GasType>;
