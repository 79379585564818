import type { StepKind } from '@sb/routine-runner';

import type { Step } from '../types';

export const countStepKinds = (
  step: Step.ConvertedSummary,
  stepKindsToInclude: StepKind[],
): number => {
  const baseCount = stepKindsToInclude.includes(step.stepKind) ? 1 : 0;

  if (!step.steps?.length) {
    return baseCount;
  }

  return step.steps.reduce(
    (total: number, substep: Step.ConvertedSummary) =>
      total + countStepKinds(substep, stepKindsToInclude),
    baseCount,
  );
};
