import type * as zod from 'zod';

import { namespace, info } from '@sb/log';
import type { ArmTarget } from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner/speed-profile';
import type { NonEmptyArray } from '@sb/utilities/src/types';

import type { PushModeStep } from '..';
import type { RoutineContext } from '../../RoutineContext';
import type ArcStep from '../Arc/Step';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';
import type WaypointStep from '../Waypoint/Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const ns = namespace('MoveArmToStepV2');

export default class MoveArmToV2Step extends Step<Arguments, Variables> {
  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  public initializeVariableState(): void {
    const { completedCount = 0 } = this.variablesForInitialization;

    this.variables = {
      completedCount,
      currentActivity: 'none',
    };
  }

  private motion?: ReturnType<RoutineContext['doMotion']>;

  public async _play({ fail, pushMode }: StepPlayArguments): Promise<void> {
    if (this.variables.currentActivity !== 'none') {
      // cannot play unless the step is doing nothing currently
      throw new Error(
        `cannot double play MoveArmToStep (current activity: ${this.variables.currentActivity})`,
      );
    }

    info(`MoveArmToStep._play`, `playing MoveArmToStep`);

    const targets: ArmTarget[] = [];

    const speedProfiles: SpeedProfile[] = [];

    // Process waypoints first
    for (const substep of this.substeps) {
      const childStep = substep as WaypointStep | ArcStep;

      // Pass the parent's completedCount to support position list progression
      const target = await childStep.getArmTarget({
        parentCompletedCount: this.variables.completedCount,
      });

      if ('failure' in target) {
        fail(target);

        return;
      }

      // Apply blend configuration based on parent config
      if (
        'useParentBlendConfig' in childStep.args &&
        childStep.args.useParentBlendConfig
      ) {
        target.blendRadius = this.args.blendConfig.radius;
      }

      // Since per-waypoint speed profiles are not yet supported, we use the parent speed profile.
      speedProfiles.push(this.args.speedProfile);

      targets.push(target);
    }

    let hasPushed = false;

    if (pushMode) {
      info(ns`_play`, `checking push mode`);

      for (const parentStep of this.parentSteps) {
        if (parentStep.getStepKind() === 'PushMode') {
          const pushModeStep = parentStep as PushModeStep;

          if (pushModeStep.hasPushed) {
            hasPushed = true;
          }

          pushModeStep.hasPushed = true;
        }
      }
    }

    const motion = this.routineContext.doMotion({
      request: {
        targets: targets as NonEmptyArray<ArmTarget>,
      },
      speedProfiles,
      pushUntilCollision: pushMode && !hasPushed,
      pushMode,
      stepID: this.id,
    });

    motion.on('requestingPlan', () => {
      if (this.variables.currentActivity === 'none') {
        this.setVariable('currentActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.currentActivity === 'requestingPlan') {
        this.setVariable('currentActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('currentActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('currentActivity', 'paused');
    });

    motion.on('complete', () => {
      this.setVariable('completedCount', this.variables.completedCount + 1);
    });

    motion.on('cancelled', () => {
      this.setVariable('currentActivity', 'none');
    });

    motion.on('failure', (failure) => fail(failure));

    this.motion = motion;

    try {
      await motion.race('failure', 'complete', 'cancelled');
    } finally {
      // Clean up regardless of how the motion ended
      this.setVariable('currentActivity', 'none');
      motion.removeAllListeners();

      delete this.motion;
    }
  }

  public _pause(): void {
    if (this.motion) {
      this.motion.emit('pause');
    }
  }

  public _resume(): void {
    if (this.motion) {
      this.motion.emit('resume');
    }
  }

  public _stop() {
    if (this.motion) {
      this.motion.emit('cancel');
    }
  }

  public hasSelfTimeout(): boolean {
    return false;
  }
}
