import * as zod from 'zod';

import { OnRobotQuickChangerCompatibleState as CompatibleState } from '../../shared/OnRobotQuickChanger/OnRobotQuickChangerCompatibleState';

export const OnRobotDualQuickChangerState = zod.object({
  kind: zod.literal('OnRobotDualQuickChanger'),
  primary: CompatibleState.optional(),
  secondary: CompatibleState.optional(),
  mountOrientation: zod.number().default(0),
});

export type OnRobotDualQuickChangerState = zod.infer<
  typeof OnRobotDualQuickChangerState
>;
