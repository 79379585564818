import type * as zod from 'zod';

import { posesAreEqual } from '@sb/geometry';
import { namespace, info } from '@sb/log';
import { ArmTarget, DEFAULT_TCP_OFFSET_OPTION } from '@sb/motion-planning';
import { getFeatureFlagSync } from '@sb/service-interfaces/host/featureFlags';

import type { PushModeStep } from '..';
import { FailureKind } from '../../FailureKind';
import type { RoutineContext } from '../../RoutineContext';
import type { StepFailure } from '../../types';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const ns = namespace('MoveArmToStep');

export default class MoveArmToStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    const { completedCount = 0 } = this.variablesForInitialization;

    this.variables = {
      completedCount,
      currentActivity: 'none',
    };
  }

  private motion?: ReturnType<RoutineContext['doMotion']>;

  public async _play({ fail, pushMode }: StepPlayArguments): Promise<void> {
    if (this.variables.currentActivity !== 'none') {
      // cannot play unless the step is doing nothing currently
      throw new Error(
        `cannot double play MoveArmToStep (current activity: ${this.variables.currentActivity})`,
      );
    }

    info(`MoveArmToStep._play`, `playing MoveArmToStep`);

    const target = await this.getArmTarget();

    if ('failure' in target) {
      return fail(target);
    }

    if (this.args.isWaypoint) {
      this.routineContext.pushWaypoint({
        armTarget: target,
        blend: this.args.blend,
      });

      return;
    }

    // TCP offsets are not applied when matching joint angles
    if (!this.args.shouldMatchJointAngles) {
      if (this.args.tcpOption === 'auto') {
        if ('tcpOption' in target && target.tcpOption) {
          // use tcpOption that target was originally created with
          this.routineContext.setTCPOffsetOption(target.tcpOption);
        } else {
          // use default tcpOption
          this.routineContext.setTCPOffsetOption(DEFAULT_TCP_OFFSET_OPTION);
        }
      } else {
        // use tcpOption that was specified in this MoveArmTo step
        this.routineContext.setTCPOffsetOption(this.args.tcpOption);
      }
    }

    let hasPushed = false;

    if (pushMode) {
      info(ns`_play`, `checking push mode`);

      for (const parentStep of this.parentSteps) {
        if (parentStep.getStepKind() === 'PushMode') {
          const pushModeStep = parentStep as PushModeStep;

          if (pushModeStep.hasPushed) {
            hasPushed = true;
          }

          pushModeStep.hasPushed = true;
        }
      }
    }

    const motion = await this.routineContext.doMotion({
      request: {
        targets: [target],
        isCacheable: this.args.isCacheable,
        smoothingType: this.args.reduceSmoothing ? 'monotone_cubic' : undefined,
      },
      speedProfiles: [this.args.speedProfile],
      pushUntilCollision: pushMode && !hasPushed,
      pushMode,
      stepID: this.id,
      moveDynamicBaseToReachPosition: this.args.moveDynamicBaseToReachPosition,
    });

    motion.on('requestingPlan', () => {
      if (this.variables.currentActivity === 'none') {
        this.setVariable('currentActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.currentActivity === 'requestingPlan') {
        this.setVariable('currentActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('currentActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('currentActivity', 'paused');
    });

    motion.on('complete', () => {
      this.setVariable('completedCount', this.variables.completedCount + 1);
    });

    motion.on('failure', (failure) => fail(failure));

    this.motion = motion;

    await motion.race('failure', 'complete');

    this.setVariable('currentActivity', 'none');
    motion.removeAllListeners();

    delete this.motion;
  }

  public _pause(): void {
    if (this.motion) {
      this.motion.emit('pause');
    }
  }

  public _resume(): void {
    if (this.motion) {
      this.motion.emit('resume');
    }
  }

  public _stop() {
    if (this.motion) {
      this.motion.emit('cancel');

      this.motion.race('failure', 'complete').then(() => {
        this.variables = {
          ...this.variables,
          currentActivity: 'none',
        };
      });
    }
  }

  private async getArmTarget(): Promise<ArmTarget | StepFailure> {
    const parseTarget = (armPosition: any) => {
      const target = {
        motionKind: this.args.motionKind,
        ...armPosition,
        calibrationPose: armPosition.pose,
      };

      if (this.args.shouldMatchJointAngles) {
        target.pose = undefined;

        if (!getFeatureFlagSync('localAccuracyCalibration', false)) {
          target.calibrationPose = undefined;
        }
      } else {
        target.jointAngles = undefined;
        target.calibrationPose = undefined;
      }

      const parsedTarget = ArmTarget.safeParse(target);

      if (!parsedTarget.success) {
        throw new Error('Move arm target is not valid');
      }

      return parsedTarget.data;
    };

    let target: ArmTarget;
    let anchor: any; // Space Item

    try {
      if ('positionListID' in this.args.target) {
        target = parseTarget(
          this.routineContext.getPositionListEntry(
            this.args.target.positionListID,
            this.args.target.positionListIndex ?? this.variables.completedCount,
          ),
        );

        const targetSpace = this.routineContext.getSpaceItem(
          this.args.target.positionListID,
        );

        if (targetSpace.anchoredToID) {
          anchor = this.routineContext.getSpaceItem(targetSpace.anchoredToID);
        }
      } else if ('expression' in this.args.target) {
        const expressionTarget = await this.routineContext.evaluateExpression(
          this.args.target.expression,
        );

        // if expression evaluates to an array, use the first item
        // this makes it easier to use 'SinglePosition' space objects.
        target = parseTarget(
          Array.isArray(expressionTarget)
            ? expressionTarget[0]
            : expressionTarget,
        );
      } else {
        target = parseTarget(this.args.target);
      }

      if (
        'positionListID' in this.args.target &&
        anchor &&
        anchor.kind === 'localAccuracyCalibration'
      ) {
        if ('pose' in target) {
          target.pose = this.routineContext.getCalibratedGoal(
            target.pose,
            anchor,
          );
        } else if ('jointAngles' in target && 'calibrationPose' in target) {
          const calibratedPose = this.routineContext.getCalibratedGoal(
            target.calibrationPose,
            anchor,
          );

          // do not change the original target object if pose did not change
          if (!posesAreEqual(target.calibrationPose, calibratedPose)) {
            const newJointAngles =
              await this.routineContext.getJointAnglesForCartesianSpacePose(
                calibratedPose,
                this.args.motionKind,
                false,
              );

            if (newJointAngles) {
              target.jointAngles = newJointAngles;
            }
          }
        }
      }

      return target;
    } catch (error) {
      return {
        failure: {
          kind: FailureKind.InvalidRoutineLoadedFailure,
        },
        failureReason: error.message,
        error,
      };
    }
  }

  public hasSelfTimeout(): boolean {
    return false;
  }
}
