import type * as zod from 'zod';

import type { CartesianPose } from '@sb/geometry';
import type { ArmJointPositions, ArmTarget } from '@sb/motion-planning';
import { EventEmitter, wait } from '@sb/utilities';

import type { RoutineContext } from '../../RoutineContext';
import type { StepFailure } from '../../types';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class PressButtonStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {
      currentActivity: 'none',
    };
  }

  private motion?: ReturnType<RoutineContext['doMotion']>;

  private events = new EventEmitter<{ pause: void; resume: void }>();

  private async doMotion(target: ArmTarget): Promise<void | StepFailure> {
    const motion = this.routineContext.doMotion({
      request: { targets: [target] },
      speedProfiles: [this.args.speedProfile],
    });

    motion.on('requestingPlan', () => {
      if (this.variables.currentActivity === 'none') {
        this.variables = { currentActivity: 'requestingPlan' };
      }
    });

    motion.on('planning', () => {
      if (this.variables.currentActivity === 'requestingPlan') {
        this.variables = { currentActivity: 'planning' };
      }
    });

    motion.on('beginMotion', () => {
      this.variables = { currentActivity: 'moving' };
    });

    motion.on('pause', () => {
      this.variables = { currentActivity: 'paused' };
    });

    this.motion = motion;

    // value includes failures if failure wins and void if complete wins
    const value = await motion.race('failure', 'complete');

    this.variables = { currentActivity: 'none' };
    motion.removeAllListeners();

    // responds with failures if failure won and void if complete won
    return value;
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    if (this.variables.currentActivity !== 'none') {
      // cannot play unless the step is doing nothing currently
      throw new Error('cannot double play PressButtonStep');
    }

    const { pressDurationMS } = this.args;

    // go to pre-press location
    let result = await this.doMotion(this.prePressDirectTarget);

    if (result) {
      return fail(result);
    }

    await this.untilPlaying();

    // press the button
    result = await this.doMotion(this.pressLineTarget);

    if (result) {
      return fail(result);
    }

    await wait(pressDurationMS);
    await this.untilPlaying();
    // move back
    result = await this.doMotion(this.prePressLineTarget);

    if (result) {
      return fail(result);
    }
  }

  // wait until we're playing again
  private untilPlaying() {
    if (this.variables.currentActivity !== 'paused') {
      return;
    }

    return this.events.next('resume');
  }

  public _pause(): void {
    if (this.motion) {
      this.motion.emit('pause');
    }
  }

  public _resume(): void {
    if (this.motion) {
      this.motion.emit('resume');
    }

    this.events.emit('resume');
  }

  public _stop() {
    if (this.motion) {
      this.motion.emit('cancel');
    }
  }

  private argToArmTarget(
    motionKind: ArmTarget['motionKind'],
    arg: CartesianPose | ArmJointPositions,
  ): ArmTarget {
    if (Array.isArray(arg)) {
      return {
        motionKind,
        jointAngles: arg,
      };
    }

    return {
      motionKind,
      pose: arg,
    };
  }

  private get prePressDirectTarget(): ArmTarget {
    return this.argToArmTarget('joint', this.args.prePressTarget);
  }

  private get prePressLineTarget(): ArmTarget {
    return this.argToArmTarget('line', this.args.prePressTarget);
  }

  private get pressLineTarget(): ArmTarget {
    return this.argToArmTarget('line', this.args.pressTarget);
  }
}
