import * as zod from 'zod';

import { BlendConfig, ZERO_BLEND_CONFIG } from '@sb/motion-planning';
import {
  ActionRequiredError,
  MotionSpeed,
  SpeedLimitOption,
  Step,
} from '@sb/remote-control/types';
import { calculateSpeedProfileFromStepMotionSpeed } from '@sb/remote-control/util/calculateSpeedProfileFromStepMotionSpeed';
import { validateMotionSpeed } from '@sb/remote-control/util/validators';
import type { StepKind } from '@sb/routine-runner';
import { type SpeedProfile } from '@sb/routine-runner';

/**
 * Types matching the routine runner schema for "MoveArmTo" steps.
 */
export namespace MoveArmToTargetStepV2 {
  export const name = 'Move arm';
  export const description =
    'Specify a location that the robot should go to by defining the joint or gripper positions';
  export const librarySection = Step.LibrarySection.Basic;
  export const librarySort = '1';
  export const featureFlag = 'moveStepV2';
  export const argumentKind = 'MoveArmToV2';

  export const isDecorator = true;

  export const permittedChildStepKinds: readonly StepKind[] = [
    'Waypoint',
    'Arc',
  ];

  export const Arguments = zod.object({
    argumentKind: zod.literal('MoveArmToV2'),
    motionSpeed: zod.preprocess(
      (val) => (val == null ? undefined : val),
      MotionSpeed.optional(),
    ),
    blendConfig: BlendConfig.optional(),
    speedLimitOption: SpeedLimitOption.exclude(['PARENT_DEFAULTS']).default(
      'NO_MOTION_LIMITS',
    ),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({ step, stepConfiguration }) => {
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    if (
      !step.steps.every((nestedStep) =>
        permittedChildStepKinds.includes(nestedStep.stepKind),
      )
    ) {
      const badStep = step.steps.find(
        (nestedStep) => !permittedChildStepKinds.includes(nestedStep.stepKind),
      );

      if (badStep == null) {
        // Should never happen
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Move Arm steps must contain only valid child steps.',
        });
      }

      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: `Move Arm steps may not contain ${badStep.name} steps.`,
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    baseSpeedProfile,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const { motionSpeed, blendConfig } = args;

    validateMotionSpeed('Move Arm', motionSpeed);

    const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed(
      {
        motionSpeed,
        baseSpeedProfile,
      },
    );

    return {
      ...stepData,
      stepKind: 'MoveArmToV2',
      args: {
        speedProfile,
        blendConfig: blendConfig || ZERO_BLEND_CONFIG,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
  }) => {
    if (args?.argumentKind !== argumentKind || !routine) {
      return null;
    }

    return 'Move arm';
  };
}

MoveArmToTargetStepV2 satisfies Step.StepKindInfo;
