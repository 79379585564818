import type * as zod from 'zod';

import { executeControlBoxIOAction } from '@sb/integrations/io/utils/execute/executeControlBoxIOAction';
import { executeFlangeIOAction } from '@sb/integrations/io/utils/execute/executeFlangeIOAction';
import { FailureKind } from '@sb/routine-runner/FailureKind';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class OperateEquipmentStep extends Step<Arguments> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    try {
      await this.handleOperateEquipment(this.args.actionID);
    } catch (error) {
      return fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Equipment action failed',
        error,
      });
    }
  }

  private async handleOperateEquipment(actionID: string) {
    const updatedAction = await this.routineContext.findAction(actionID);

    if (updatedAction == null) {
      throw new Error(`Action not found: ${actionID}`);
    }

    if (updatedAction.actionOutputs?.kind === 'controlBoxIO') {
      await executeControlBoxIOAction(updatedAction.actionOutputs, {
        robot: this.routineContext.robot,
      });
    } else if (updatedAction.actionOutputs?.kind === 'flangeIO') {
      await executeFlangeIOAction(updatedAction.actionOutputs, {
        robot: this.routineContext.robot,
      });
    }
  }
}
