import * as zod from 'zod';

import {
  CustomDeviceActionConfiguration,
  CustomDeviceConfiguration,
} from '@sb/integrations/implementations/CustomDevice/types/CustomDeviceConfiguration';
import { ActionRequiredError, Step } from '@sb/remote-control/types';

import { SetIOStep } from './setIO';

export namespace OperateEquipmentStep {
  export const name = 'Operate Equipment';
  export const description =
    "Communicate with other devices defined in this robot's Equipment.";
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const argumentKind = 'OperateEquipment';
  export const featureFlag = 'customDevices';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    equipment: CustomDeviceConfiguration.optional(),
    action: CustomDeviceActionConfiguration.optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({
    stepConfiguration,
    ioOutputs,
  }) => {
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    const usedOutputs = ioOutputs.filter((output) => {
      if (
        output.kind !== 'Output' &&
        output.kind !== 'Relay' &&
        output.kind !== 'FlangeOutput'
      ) {
        return false;
      }

      const actionOutputs = args.action?.actionOutputs;

      if (actionOutputs == null || actionOutputs.kind !== 'controlBoxIO') {
        return false;
      }

      return actionOutputs.outputs.some(
        (actionOutput) =>
          actionOutput.ioPort ===
          SetIOStep.getPortLabel({
            kind: output.kind,
            port: output.port,
            level: 'low',
          }),
      );
    });

    const usedSafetyIOs = usedOutputs.filter(
      (output) => output.assignedTo?.kind === 'safetyIO',
    );

    if (usedSafetyIOs.length > 0) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Safety I/O outputs cannot be set using actions',
        fieldId: 'outputs',
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (args.equipment == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Equipment required',
        fieldId: 'equipment',
      });
    }

    if (args.action == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Action required',
        fieldId: 'action',
      });
    }

    return {
      ...stepData,
      stepKind: 'OperateEquipment',
      args: {
        actionID: args.action.id,
      },
    };
  };
}

OperateEquipmentStep satisfies Step.StepKindInfo;
