import * as zod from 'zod';

import { BlendConfig, MotionKind, TCPOptionOrAuto } from '@sb/motion-planning';
import {
  ActionRequiredError,
  MotionSpeed,
  SpeedLimitOption,
  Step,
} from '@sb/remote-control/types';
import type { WaypointStepArguments } from '@sb/routine-runner';
import type { SpeedProfile } from '@sb/routine-runner/speed-profile';
import { Expression, Space } from '@sb/routine-runner/types';

import { calculateSpeedProfileFromStepMotionSpeed } from '../util/calculateSpeedProfileFromStepMotionSpeed';
import convertJavaScriptExpressionToUser from '../util/expressions/convertJavaScriptExpressionToUser';
import { convertPythonExpression } from '../util/expressions/v2';
import { validateCodeExpression } from '../util/expressions/validateCodeExpression';
import { validateMotionSpeed } from '../util/validators';

export namespace WaypointStep {
  export const name = 'Waypoint';
  export const description = 'Move the robot to a specific position in space';
  export const librarySection = Step.LibrarySection.Hidden;
  export const librarySort = '1'; // unused
  export const argumentKind = 'Waypoint';
  export const featureFlag = 'moveStepV2';

  const permittedParentStepKinds = ['MoveArmToV2', 'Weld'];

  export const Arguments = zod.object({
    argumentKind: zod.literal('Waypoint'),
    shouldMatchJointAngles: zod.boolean().default(false),
    motionKind: MotionKind.default('joint'),
    motionKindRaw: MotionKind.default('joint'),
    motionSpeed: zod.preprocess(
      (val) => (val == null ? undefined : val),
      MotionSpeed.optional(),
    ),
    speedLimitOption: SpeedLimitOption.default('PARENT_DEFAULTS'),
    positionListID: zod.string().nullable().default(null),
    positionListIndex: zod.number().optional(),
    target: Space.Position.nullable().default(null),
    tcpOption: TCPOptionOrAuto.default('auto'),
    expression: Expression.optional(),
    targetKind: zod
      .union([
        zod.literal('singlePosition'),
        zod.literal('positionList'),
        zod.literal('expression'),
      ])
      .default('singlePosition'),
    stopHere: zod.boolean().default(false),
    stopHereRaw: zod.boolean().default(false),
    blendConfig: BlendConfig.default({ kind: 'blendRadius', radius: 0 }),
    useParentBlendConfig: zod.boolean().default(true),
    moveDynamicBaseToReachPosition: zod.boolean().default(true),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({
    step,
    stepConfiguration,
    routine: { space },
    globalSpace,
  }) => {
    const spaceItems = [...globalSpace, ...space];
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    // Get the parent step kind
    const parentStepKind = step.parentSteps?.at(-1)?.stepKind ?? '';

    if (!permittedParentStepKinds.includes(parentStepKind)) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          'Waypoint steps may only be used inside a Move Arm or Weld step',
      });
    }

    // Validate that for Weld steps, the tcpOption must be 'auto'
    if (parentStepKind === 'Weld' && args.tcpOption !== 'auto') {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          'TCP Option must be set to "auto" when used within a Weld step',
        fieldId: 'tcpOption',
      });
    }

    // checkValidPositionConfig

    const hasTarget = !!(args.target?.jointAngles || args.target?.pose);

    if (args.targetKind === 'singlePosition' && !hasTarget) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Joint angles or pose must be set',
        fieldId: 'jointAngles',
      });
    }

    // checkPositionListDefined

    if (args.targetKind === 'positionList') {
      if (!args.positionListID) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Position list must be set',
          fieldId: 'positionListID',
        });
      }

      const spaceItem = spaceItems.find((s) => s.id === args.positionListID);

      if (spaceItem == null) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Choose a valid space item',
          fieldId: 'positionListID',
        });
      }

      if (
        args.positionListIndex != null && // If null, then cycling through list.
        (args.positionListIndex < 0 ||
          args.positionListIndex >= spaceItem.positions.length) // If index is out of bounds. (NOTE: Do not check the space type. This could be an issue if the space item is changed to a non-position list. Under the hood, RoutineContext#getPositionListEntry can handle this, however, the user probably doesn't want to use an invalid/out-of-bounds index.)
      ) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Choose a valid index for the position list.',
          fieldId: 'positionListIndex',
        });
      }
    }

    if (args.targetKind === 'expression' && args.expression != null) {
      validateCodeExpression(
        args.expression,
        'expression',
        'Position expression',
      );
    }

    // checkAddOffsetJointAngles

    const hasAddOffsetParentStep = step.parentSteps.some(
      (parentStep) => parentStep?.stepKind === 'AddOffset',
    );

    if (hasAddOffsetParentStep && (args.shouldMatchJointAngles ?? false)) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          'Waypoint steps inside an Add Offset step may not require exact final joint position, as only the tooltip pose can be guaranteed.',
        fieldId: 'shouldMatchJointAngles',
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    baseSpeedProfile,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const {
      target,
      expression,
      targetKind,
      shouldMatchJointAngles,
      motionKind,
      motionSpeed,
      speedLimitOption,
      positionListID,
      positionListIndex,
      stopHere,
      blendConfig,
      useParentBlendConfig,
      tcpOption,
    } = args;

    validateMotionSpeed(name, motionSpeed);

    const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed(
      {
        motionSpeed,
        baseSpeedProfile,
      },
    );

    const getTarget = (): WaypointStepArguments['target'] => {
      switch (targetKind) {
        case 'positionList':
          if (positionListID) {
            return {
              positionListID,
              positionListIndex,
            };
          }

          break;

        case 'expression':
          if (expression) {
            return { expression };
          }

          break;

        default:
          if (target) {
            return target;
          }
      }

      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Target is not configured',
      });
    };

    // Cannot blend and stop at the same time - if stopping, don't use parent blend config
    const effectiveUseParentBlendConfig = stopHere
      ? false
      : useParentBlendConfig;

    return {
      ...stepData,
      stepKind: 'Waypoint',
      args: {
        target: getTarget(),
        shouldMatchJointAngles,
        motionKind,
        stopHere,
        blendConfig,
        useParentBlendConfig: effectiveUseParentBlendConfig,
        speedProfile,
        useParentSpeedProfile: speedLimitOption === 'PARENT_DEFAULTS',
        tcpOption,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
    includeStepName,
    globalSpace,
  }) => {
    if (args?.argumentKind !== argumentKind || !routine) {
      return null;
    }

    // Helper function to prefix step name if required
    const prefixStepName = (description_: string) =>
      includeStepName ? `${name} ${description_}` : description_;

    if (args.targetKind === 'singlePosition') {
      // arbitrary specified point
      return prefixStepName('to manually specified position');
    }

    const allSpace = [...(globalSpace ?? []), ...routine.space];

    if (args.targetKind === 'positionList') {
      const space = allSpace.find((item) => item.id === args.positionListID);

      if (!space) {
        return null;
      }

      if (space.kind === 'singlePosition') {
        return prefixStepName(`to ${space.name}`);
      }

      if (space.kind === 'safeHomePose') {
        return prefixStepName('to home pose');
      }

      if (
        space.kind === 'freeformPositionList' ||
        space.kind === 'gridPositionList'
      ) {
        if (args.positionListIndex == null) {
          return prefixStepName(`to the next position in ${space.name}`);
        }

        return prefixStepName(
          `to position ${args.positionListIndex + 1} in ${space.name}`,
        );
      }

      return null;
    }

    if (args.targetKind === 'expression') {
      if (args.expression == null) {
        return null;
      }

      if (args.expression.kind === 'JavaScript') {
        // This looks pretty gnarly for most expressions. Probably want to allow
        // the user to build up the expression using a DSL that we can desugar
        // into JS later.
        const jsExprStr = convertJavaScriptExpressionToUser(
          args.expression.expression,
          { isForStepDescription: true },
        );

        return prefixStepName(`based on expression ${jsExprStr}`);
      }

      if (args.expression.kind === 'Python') {
        const { code: pyExprStr } =
          convertPythonExpression.withRemoteControlVariables.toUserDescription(
            args.expression.expression,
          );

        return prefixStepName(`based on expression ${pyExprStr}`);
      }

      return null;
    }

    return null;
  };
}

WaypointStep satisfies Step.StepKindInfo;
