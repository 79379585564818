/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { isControlPanelAccessValid } from '@sbrc/services/feathers-client/control-panel/controlPanelAuth';

interface ControlPanelProtectionProps {
  children: React.ReactNode;
}

/**
 * A component that protects control panel routes by checking authentication validity
 * If authentication is not valid, it redirects to the main control panel page
 */
export function ControlPanelProtection({
  children,
}: ControlPanelProtectionProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    setIsAuthenticated(isControlPanelAccessValid());
  }, []);

  useEffect(() => {
    // Skip if we're already on the main control panel page
    if (location.pathname === '/control-panel') {
      return undefined;
    }

    const checkInterval = setInterval(() => {
      const isValid = isControlPanelAccessValid();

      // Only update state if the value changed (to avoid unnecessary renders)
      if (isValid !== isAuthenticated) {
        setIsAuthenticated(isValid);
      }

      if (!isValid) {
        navigate('/control-panel', { replace: true });
      }
    }, 1000);

    return () => {
      clearInterval(checkInterval);
    };
  }, [location.pathname, navigate, isAuthenticated]);

  // If we're on the main control panel page, just render children
  // This allows the ControlPanelHome component to handle PIN entry
  if (location.pathname === '/control-panel') {
    return <>{children}</>;
  }

  // If authentication check is complete and user is not authenticated,
  // redirect immediately using Navigate component
  if (isAuthenticated === false) {
    return <Navigate to="/control-panel" replace />;
  }

  // If still checking authentication (null) show nothing to prevent content flash
  if (isAuthenticated === null) {
    return <></>;
  }

  // If authenticated, render the protected content
  return <>{children}</>;
}
